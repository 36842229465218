
.popup__quit__container {
    position: relative;
    max-width: 530px !important;
    padding: 110px 15px 40px;
}

.popup__top__img {
    position: absolute;
    top: -66px;
    left: 50%;
    transform: translateX(-50%);
    width: 166px;
    height: 170px;

    @media screen and (max-width: $mobile-xl) {
        position: static;
        transform: translateX(0);
        margin: 0 auto;
    }
}

.popup__support {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 20px;
    color: #858585;

    img {
        margin-right: 5px;
    }
}

.popup__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #2d2a26;

    &__accent {
        margin-bottom: 10px;
        font-weight: 700 !important;
        font-size: 18px !important;
    }

    &__default {
        margin-bottom: 20px;
    }

    span {
        font-weight: 500;
        line-height: 20px;
    }
}

.popup__btns {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 18px;
}

.popup__btn__fill {
    width: 100%;
    max-width: 300px;
    height: 56px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background-color: #39445a;
    transition: all .3s;

    &:hover {
        background-color: #283040;
    }
}

.popup__btn__underlined {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #2d2a26;
    color: #2d2a26;
    transition: all .3s;

    &:hover {
        text-decoration-color: transparent;
    }

    @media (hover: none) {
        text-decoration-color: #2d2a26;
    }
}