// breakpoints
$mobile-xxs: 300px;
$mobile-xs: 320px;
$mobile-s: 360px;
$mobile-m: 400px;
$mobile-l: 414px;
$mobile-xl: 480px;

$tablet-s: 567px;
$tablet-m: 599px;
$tablet-l: 700px;
$tablet-xl: 768px;

$laptop-m: 847px;
$laptop-l: 899px;
$laptop-xl: 1024px;

$desktop-xxs: 1156px;
$desktop-xs: 1199px;
$desktop-s: 1280px;
$desktop-m: 1366px;
$desktop-l: 1480px;
$desktop-xl: 1600px;
$desktop-xxl: 1800px;

// fonts
$primary-font-family: Montserrat, sans-serif;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-bolder: 800;

// colors
$background-dark-color: #111a29;
$background-dark-hover-color: #21264c;
$background-light-color: #ffffff;
$background-red-color: #e10000;
$background-sidemenu-color: #111A29;
$background-sidemenu-item-color: #172337;
$background-sidemenu-open-dropdown-color: #1F2F4A;
$background-sidemenu-item-hover-color: linear-gradient(90deg, #002E7C 0%, #172337 100%);
$background-sidemenu-dropdown-caret-color: #1C2B43;
$background-cards-color: #0b233d;
$background-seo-color: #0e1622;

$scrollbar-track-color: #020105;
$scrollbar-thumb-color: #696969;
$popup-overlay-color: #04040bcc;
$popup-close-icon-color: #73788c;
$popup-divider-color: #cbc7c7;
$input-border-color: #9ca4bf;
$input-background-color: #f5f6fa;
$input-error-color: #d43b3b;
$input-success-color: #008000;
$footer-border-color: #2f3b52;

$text-light-color: #ffffff;
$text-light-hover-color: #ffffffb3;
$text-dark-color: #2d2a26;
$text-black-color: #000000;
$text-gray-color: #999dad;
$footer-link-color: #b1c3e5;
$popup-link-color: #2826a5;
$sidemenu-item-hover-color: #eaeaea;
$signup-indicator-line: #9e9e9e;
$signup-indicator-background: #555353;
$signup-indicator-step: #12845b;

$button-green-primary-color: #87f031;
$button-green-primary-hover-color: #8fe846;
$button-green-primary-disabled-color: #c8f0a8;
$button-green-secondary-hover-color: #87f03133;
$button-submit-color: #38445a;
$button-submit-hover-color: #283040;
$button-submit-disabled-color: #d3d4d6;

// sidemenu
$side-menu-open-width: 254px;
$side-menu-close-width: 84px;