.popup__content:has(.forgotPass__popup__body) {
    & > h1 {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
    }

    & > p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-transform: none;
        text-align: center;
    }

    img:first-of-type {
        margin: 0;
    }
}

.forgotPass__popup__body {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    height: max-content;

    #confirmation__code {
        display: none;
    }

    form:has(.input__row) {
        button:only-of-type {
            margin-top: 24px;
        }
    }

    form {
        input {
            padding: 8px 16px;
            width: 100%;
            height: 48px;
            border: 1px solid #9CA4BF;
            border-radius: 4px;
            background-color: #F5F6FA;
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
            outline: none;

            &::placeholder,
            &::-moz-placeholder,
            &::-ms-input-placeholder,
            &::-webkit-input-placeholder {
                color: #9CA4BF;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                margin: 0;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
            }

            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 100px #fff inset;
                -moz-box-shadow: 0 0 0 100px #fff inset;
                box-shadow: 0 0 0 100px #fff inset;
            }

            &[type=number],
            &[type=number]:focus,
            &[type=number]:hover {
                -moz-appearance: textfield;
                -webkit-appearance: textfield;
            }

            &.error {
                border: 1px solid #C82C16;
            }
        }

        fieldset.input__line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
            margin: 8px;
            border: none;

            div {
                position: relative;

                input {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    width: 40px;
                    height: 45px;
                    text-align: center;
                }

                &.active {
                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        left: 9px;
                        bottom: 8px;
                        width: 22px;
                        height: 1px;
                        background-color: #73788C;
                    }
                }
            }

            &.error {
                div {
                    input {
                        border: 1px solid #C82C16;
                    }
                }
            }
        }

        .input__row {
            .forgotPass__input__wrapper {
                position: relative;
    
                input {
                    padding-right: 50px;
                }
    
                [data-view-password] {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    padding: 16px;
                    width: max-content;
                    height: 100%;
                    border: none;
                    border-radius: 8px;
                    background-color: transparent;
                    box-shadow: none;
                    transition: none;
    
                    &.hide__password {
                        img:first-child {
                            display: flex;
                        }
        
                        img:last-child {
                            display: none;
                        }
                    }
        
                    &.show__password {
                        img:first-child {
                            display: none;
                        }
        
                        img:last-child {
                            display: flex;
                        }
                    }
                }
            }

            .input__hints__wrapper {
                width: 70%;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 3px;
                margin-left: -7px;
                padding-top: 2px;
            }
    
            .hint {
                display: inline-block;
                position: relative;
                padding-left: 15px;
                color: #2d2a26;
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                white-space: nowrap;
    
                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 7px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #9CA4BF;
                }
    
                &.hidden {
                    display: none;
                }
                &.success {
                    &:before {
                        top: 3px;
                        left: 7px;
                        width: 6px;
                        height: 6px;
                        background: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 5L1 2.89474' stroke='%2324994C' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50%
                    }
                }
                &.unsuccessful {
                    &:before {
                        top: 3px;
                        left: 7px;
                        width: 7px;
                        height: 7px;
                        background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_32675_200425)'%3E%3Cpath d='M5 1L1 5' stroke='%239CA4BF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L5 5' stroke='%239CA4BF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_32675_200425'%3E%3Crect width='6' height='6' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat 50%;
                    }
                }
            }

            .phone__number__row {
                display: grid;
                grid-template-columns: 68px 1fr;
                justify-items: center;
                justify-content: center;
                gap: 12px;
                width: 100%;
    
                .country__code__box {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    padding: 16px;
                    width: 100%;
                    max-width: 68px;
                    max-height: 48px;
                    height: 100%;
                    border-radius: 4px;
                    border: 1px solid #9ca4bf;
                    background: #f5f6fa;
                    color: #2d2a26;
                    font-size: 13px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 125%;
                    outline: none;
                    -webkit-appearance: none;
                    appearance: none;
                    box-sizing: border-box;
    
                    & > img {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                    }
    
                    & > span {
                        position: relative;
                        flex-grow: 1;
                        color: #898996;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }
            }
    
            .phone__number__hint {
                font-size: 10px;
                font-weight: 400;
                font-style: italic;
                line-height: 16px;
            }
        }
    }

    .timer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 26px;
        color: #2D2A26;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        white-space: pre;

        button {
            background-color: transparent;
            text-decoration: underline;
            box-shadow: none;
            outline: none;
            border: none;
            text-align: center;
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        width: 100%;
        border: none;
        border-radius: 8px;
        background-color: #38445A;
        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        box-shadow: 0 16px 32px -3px rgba(56, 68, 90, .15), 0 4px 8px -3px rgba(56, 68, 90, .15);
        transition: background-color .3s;

        &:hover {
            background-color: #181d26;
            box-shadow: 0 4px 4px 0 hsla(240, 8%, 61%, .6);
        }

        &:disabled {
            background: #ebedef;
            box-shadow: none;
            cursor: default;
        }

        &.simple {
            width: 100%;
            padding: 0;
            background-color: transparent;
            color: #2D2A26;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-decoration: underline;
            text-decoration-color: #2D2A26;
            box-shadow: none;
            outline: none;
            border: none;
            text-align: center;
            transition: text-decoration-color .3s ease-in-out;

            &:hover {
                background-color: transparent;
                box-shadow: none;
                text-decoration-color: transparent;
            }
        }

        &:not(:only-child) {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    p {
        &.sms__hint {
            color: #2D2A26;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
        }

        button {
            width: 100%;
            padding: 0;
            background-color: transparent;
            color: #2D2A26;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-decoration: underline;
            text-decoration-color: #2D2A26;
            box-shadow: none;
            outline: none;
            border: none;
            text-align: center;
            transition: text-decoration-color .3s ease-in-out;

            &:after {
                content: '?';
                display: inline;
                width: max-content;
                height: auto;
                font-weight: 400;
                text-decoration: none;
            }

            &:hover {
                background-color: transparent;
                box-shadow: none;
                text-decoration-color: transparent;
            }
        }
    }
}