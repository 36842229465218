.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: $popup-overlay-color;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.is__open {
        @media screen and (max-width: $desktop-s) {
            display: block;
        }
    }
}

header .header__nav.topmenu {
    ul {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        @media screen and (max-width: $laptop-xl) {
            padding: 16px 16px 35px;
        }

        @media screen and (max-width: $tablet-m) {
            padding: 12px 16px 120px;
        }

        li {
            width: 100%;

            a,
            &>span {
                display: flex;
                align-items: center;
                padding: 6.5px 32px;
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                line-height: 48px;
                border-radius: 0;
                cursor: pointer;

                @media screen and (max-width: $mobile-s) {
                    font-size: 14px;
                }

                &:hover {
                    background-color: $background-sidemenu-item-color;
                    color: $sidemenu-item-hover-color;
                }

                img {
                    display: none;
                }
            }
        }
    }
}

header .header__nav.sidemenu {
    flex: 0;

    &.is__open {
        .ul_wrapper {
            max-width: $side-menu-open-width;

            ul li {
                .label {
                    visibility: visible;
                    opacity: 1;
                }

                &.accordion .accordion__header .dropdown-caret {
                    visibility: visible;
                    opacity: 1;
                }
            }

            @media screen and (max-width: $laptop-xl) {
                left: 0;
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
            }

            @media screen and (max-width: $mobile-xl) {
                max-width: 100%;
            }
        }
    }

    &:not(.is__open) {
        .ul_wrapper ul li.accordion.open .accordion__body {
            max-height: 0 !important;
        }
    }

    .ul_wrapper {
        position: fixed;
        top: 64px;
        overflow-y: auto;
        overscroll-behavior-y: contain;
        overflow-x: hidden;
        width: 100%;
        height: calc(100% - 64px);
        scrollbar-width: none;
        background-color: $background-sidemenu-color;
        transform: translateX(-16px);

        ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px 16px 0;
            min-height: 100%;

            @media screen and (max-width: $laptop-xl) {
                padding: 16px 16px 35px;
            }

            @media screen and (max-width: $tablet-m) {
                padding: 16px 16px 120px;
            }

            .calendar {
                text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
                background: linear-gradient(180deg, #166C47 0%, #1B3B2F 100%) !important;
                position: relative;

                a {
                    background: none !important;
                    z-index: 1;
                }

                .falling_stars_animation {
                    z-index: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        opacity: 0.8;
                        user-select: none;
                        object-fit: cover;
                        pointer-events: none;
                        position: relative;
                    }
                }
            }

            li {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 16px;
                border-radius: 4px;
                cursor: pointer;

                &>a,
                &>span,
                &>span>div {
                    height: 39px;
                    min-height: 39px;

                    &:hover {
                        background: $background-sidemenu-item-hover-color;
                    }

                    @media screen and (max-width: $mobile-xl) {
                        height: 46px;
                        min-height: 46px;
                    }
                }

                &>a,
                &>span,
                &>span>div,
                .accordion__body>a,
                .accordion__body>span,
                .accordion__body>div {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 0 16px;
                    width: 100%;
                    color: #fff;
                    font-family: $primary-font-family;
                    font-style: normal;
                    font-weight: $font-medium;
                    font-size: 14px;
                    text-align: left;
                    transition: all .3s ease-in-out;
                    cursor: pointer;
                    background: $background-sidemenu-item-color;
                    transition: none;

                    @media screen and (max-width: $mobile-s) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $mobile-xl) {
                        height: 46px;
                        min-height: 46px;
                    }

                    img {
                        display: block;
                        width: 20px;
                        height: auto;
                    }
                }

                &>span>div {
                    padding: 0;
                }

                &.accordion {
                    background: $background-sidemenu-item-color;
                    .accordion__header {
                        background: $background-sidemenu-item-color;
                        border-radius: 4px;
                        transition: none;
                        position: relative;
                        height: 39px;
                        min-height: 39px;

                        @media (max-width: $mobile-xl) {
                            height: 46px;
                            min-height: 46px;
                        }

                        &:hover {
                            background: $background-sidemenu-item-hover-color;
                        }

                        &>div {
                            display: flex;
                        }

                        .dropdown-caret {
                            position: absolute;
                            right: 8px;
                            background-color: $background-sidemenu-dropdown-caret-color;
                            transition: all 0.2s;
                            width: 28px;
                            height: 28px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                height: 13px;
                                user-select: none;
                            }
                        }
                    }

                    .accordion__body {
                        width: 100%;
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.2s ease-out;

                        &>a,
                        &>span,
                        &>div {
                            display: flex;
                            justify-content: flex-start;
                            gap: 8px;
                            padding: 0 16px;
                            height: 36px;
                            min-height: 36px;
                            text-transform: capitalize;
                            border-radius: 4px;

                            &:hover {
                                background-color: $background-sidemenu-open-dropdown-color;
                            }

                            &:last-child {
                                border-radius: 0 0 4px 4px;
                                text-transform: none;
                            }

                            @media screen and (max-width: $mobile-xl) {
                                height: 44px;
                                min-height: 44px;
                            }
                        }
                    }

                    &.open {
                        .accordion__header {
                            background: $background-sidemenu-open-dropdown-color;

                            .dropdown-caret {
                                transform: rotate(180deg);
                            }

                            &:hover {
                                background: $background-sidemenu-item-hover-color;
                            }
                        }

                        .accordion__body {
                            max-height: max-content;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $mobile-xl) {
            top: 58px;
            max-width: unset;
            height: calc(100% - 58px);
        }

        @media screen and (max-width: $mobile-s) {
            top: 54px;
            height: calc(100% - 54px); 
        }
    }
}

header:has(#app__panel.open) {
    .sidemenu .ul_wrapper {
        height: calc(100% - 109px);
        top: 109px;

        @media screen and (max-width: $mobile-xl) {
            height: calc(100% - 105px);
            top: 105px;
        }

        @media screen and (max-width: $mobile-s) {
            height: calc(100% - 99px);
            top: 99px;
        }
    }
}