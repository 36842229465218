main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px;
    width: 100%;
    min-height: 100dvh;

    @media screen and (max-width: $mobile-xl) {
        padding-top: 48px;
    }

    .main__choices {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        max-width: 1330px;
        margin: 0 auto;

        @media screen and (max-width: $desktop-m) {
            max-width: 90vw;
        }

        @media screen and (max-width: $desktop-xs) {
            padding: 0 16px;
            max-width: 100%;
        }

        @media screen and (max-width: $laptop-l) {
            flex-direction: column; 
        }

        @media screen and (max-width: $mobile-xl) {
            padding: 0 10px;
        }

        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: calc(50% - 12px);
            border-radius: 8px;
            overflow: hidden;
            background-color: $background-cards-color;

            @media screen and (max-width: $laptop-l) {
                width: 100%;
                border-radius: 0;
                background-color: transparent;
            }

            .main__choices__img-wrapper {
                width: auto;
                height: 240px;
                display: block;
                cursor: pointer;
        
                @media screen and (max-width: $mobile-xl) {
                    width: 100%;
                    height: auto;
                }

                picture {
                    display: inline;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
    
                        @media screen and (max-width: $laptop-l) {
                            border-radius: 8px;
                        }
                    }
                }
            }

            h1 {
                margin: 18px auto 24px;
                max-width: 560px;
                height: 64px;
                color: $text-light-color;
                display: block;
                font-family: $primary-font-family;
                font-size: 24px;
                font-weight: $font-bold;
                line-height: 32px;
                text-align: center;
                text-transform: uppercase;
                outline: none;
                text-decoration: none;
                white-space: pre-line;
                cursor: pointer;

                @media screen and (max-width: $laptop-l) {
                    margin: 16px auto;
                    text-align: center;
                    max-width: 460px;
                }

                // fix for layout shift
                &:not(:has(br)) {
                    @media screen and (max-width: 448px) {
                        height: 96px;
                    }
                }
            }

            button {
                padding: 16px;
                margin-bottom: 24px;
                width: auto;
                height: 54px;
                border-radius: 4px;
                font-family: $primary-font-family;
                font-weight: $font-bold;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                box-shadow: none;
                background-color: $button-green-primary-color;
                border: 1px solid $button-green-primary-color;
                color: $text-dark-color;
                text-transform: uppercase;

                @media screen and (max-width: $laptop-l) {
                    margin-bottom: 0;
                    width: 100%;
                    height: 45px;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 12px;
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }

    .main__vip {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 42px 0;
        width: 100%;
        max-width: 1330px;
        height: max-content;

        @media screen and (max-width: $laptop-l) {
            margin-top: 23px;
        }

        @media screen and (max-width: $mobile-xl) {
            margin: 40px 0 30px;
        }

        a {
            position: relative;
            display: flex;
            margin: 0 auto;
            padding: 60px 33px;
            width: 100%;
            border-radius: 20px;
            background: linear-gradient(135deg, #262d3b 4.86%, #2a3243 54.91%, #1f2531);

            @media screen and (max-width: $desktop-m) {
                max-width: 90vw;
                height: 300px;
                padding: 52px 33px;
            }
        
            @media screen and (max-width: $desktop-xs) {
                height: 250px;
                max-width: calc(100% - 32px);
                margin: 0 16px;
                padding: 36px 33px;
            }
        
            @media screen and (max-width: $laptop-l) {
                height: 223px;
                padding: 33px;
            }
        
            @media screen and (max-width: $tablet-l) {
                height: 180px;
            }
        
            @media screen and (max-width: $tablet-m) {
                padding: 38px 16px;
                border-radius: 8px;
            }
        
            @media screen and (max-width: $mobile-xl) {
                height: 150px;
                padding: 24px 16px;
                max-width: calc(100% - 20px);
                margin: 0 10px;
            }
        
            @media screen and (max-width: $mobile-m) {
                padding: 18px 16px;
            }

            .main__vip__textarea {
                display: flex;
                flex-direction: column;
                max-width: 465px;

                h1 {
                    margin-bottom: 13px;
                    -webkit-text-fill-color: transparent;
                    text-fill-color: transparent;
                    -moz-text-fill-color: transparent;
                    background: linear-gradient(100deg, #f1cf9f, #f0cd9b .01%, #dca557);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    background-size: 100%;
                    color: $text-dark-color;
                    font-family: $primary-font-family;
                    font-style: normal;
                    font-size: 40px;
                    font-weight: $font-bolder;
                    line-height: 1.4;
                    letter-spacing: -.015em;
                    text-align: left;
                    text-transform: uppercase;

                    @media screen and (max-width: $desktop-m) {
                        font-size: 36px;
                    }
            
                    @media screen and (max-width: $desktop-xs) {
                        font-size: 32px;
                    }
            
                    @media screen and (max-width: $laptop-l) {
                        max-width: 365px;
                        margin-bottom: 12px;
                        font-size: 28px;
                    }
            
                    @media screen and (max-width: $tablet-l) {
                        max-width: 220px;
                        font-size: 18px;
                    }
            
                    @media screen and (max-width: $mobile-xl) {
                        max-width: 175px;
                        margin-bottom: 12px;
                        font-size: 16px;
                    }
                }

                h2 {
                    color: $text-light-color;
                    font-family: $primary-font-family;
                    font-size: 20px;
                    font-weight: $font-regular;
                    line-height: 1.4;
                    text-decoration: underline;
                    text-decoration-color: $text-light-hover-color;
                    transition: text-decoration-color .3s ease-in-out;

                    @media screen and (max-width: $laptop-xl) {
                        min-height: 40px;
                        font-size: 16px;
                    }
            
                    @media screen and (max-width: $tablet-l) {
                        font-size: 14px;
                    }
            
                    @media screen and (max-width: $tablet-m) {
                        font-size: 12px;
                    }

                    @media screen and (max-width: $mobile-m) {
                        max-width: 150px;
                    }
                }
            }

            img {
                position: absolute;
                max-height: 344px;
                right: -3%;
                top: 50%;
                width: auto;
                height: 105%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);

                @media screen and (max-width: $desktop-l) {
                    right: -1%;
                }

                @media screen and (max-width: $desktop-m) {
                    max-height: 280px;
                }
        
                @media screen and (max-width: $desktop-xs) {
                    max-height: 240px;
                }
        
                @media screen and (max-width: $laptop-xl) {
                    max-height: 225px;
                }
        
                @media screen and (max-width: $laptop-l) {
                    max-height: 23vw;
                }
        
                @media screen and (max-width: $tablet-l) {
                    max-height: 28vw;
                }
        
                @media screen and (max-width: $tablet-m) {
                    max-height: 27vw;
                }
        
                @media screen and (max-width: $mobile-xl) {
                    max-height: 25vw;
                }
        
                @media screen and (max-width: $mobile-s) {
                    max-height: 22vw;
                }
            }

            &:hover {
                .main__vip__textarea h2 {
                    text-decoration-color: transparent;
                }
            }
        }
    }

    .slider__section {
        height: max-content;
        overflow: hidden;

        .slider__title {
            height: 50px;
            text-align: center;
            margin-bottom: 16px;
            font-size: 36px;
            line-height: 50px;
            color: $text-light-color;
            text-transform: uppercase;
            font-family: $primary-font-family;
            font-weight: $font-bold;

            @media screen and (max-width: $laptop-l) {
                height: 36px;
                font-size: 26px;
                line-height: 36px;
            }

            @media screen and (max-width: $mobile-xl) {
                margin-bottom: 12px;
                height: 25px;
                font-size: 18px;
                line-height: 25px;
            }
        }
    }

    .main__slider {
        margin: 0 24px 24px;
        width: calc(100% - 48px);

        @media screen and (max-width: $desktop-s) {
            margin: 0 25% 24px;
            width: 50%;
        }

        @media screen and (max-width: $laptop-l) {
            margin: 0 11% 44px;
            width: 78%;
            height: 100%;
        }

        @media screen and (max-width: $mobile-xl) {
            margin: 0 5% 12px;
            width: 90%;
        }

        .slider__wrapper {
            width: 100%;
            position: relative;
            margin-left: calc(-16px * (var(--i, 1)));

            @media screen and (max-width: $desktop-s) {
                margin-left: 0;
            }

            @media screen and (max-width: $laptop-l) {
                height: 100%;
            }

            &_left,
            &_right {
                display: block;
                position: absolute;
                width: 8px;
                height: 100%;
                background-color: $background-dark-color;
                top: 0;
                z-index: 1;

                @media screen and (max-width: $desktop-s) {
                    display: none;
                }
            }

            &_left {
                left: calc(-24px + 16px * (var(--i, 1)));
            }

            &_right {
                right: calc(-24px - 16px * (var(--i, 1)));
            }
        }

        .slider {
            display: flex;
            align-items: center;
            gap: 16px;
            overflow-y: hidden;
            --n: 1;
            width: 100%;
            width: calc(var(--n)*100%);
            transform: translate(calc(var(--tx, 0px) + var(--i, 1)/var(--n)*-100%));

            @media screen and (max-width: $desktop-s) {
                width: 100%;
                width: calc(var(--n)*100%);
                transform: translate(calc(var(--tx, 5px) + var(--i, 2)/var(--n)*-100%));
            }
            
            &.smooth { 
                transition: transform 0.5s ease-out; 
            }

            .slide {
                width: calc(100% / (var(--n) * 3) - (16px * 2 / 3)); // 3 is quantity of visible slides, 2 is quantity of visible gaps
                border-radius: 8px;
                overflow: hidden;
                cursor: pointer;
                position: relative;

                @media screen and (max-width: $desktop-s) {
                    width: calc(100% / (var(--n)) - (16px * 2 / 3));
                }

                @media screen and (max-width: $laptop-l) {
                    height: 100%;
                }

                img {
                    pointer-events: none;
                    user-select: none;
                    width: 100%;
                    height: 100%;
                }

                button {
                    position: absolute;
                    bottom: 24px;
                    left: 24px;
                    font-family: $primary-font-family;
                    color: $text-light-color;
                    font-weight: $font-semibold;
                    font-size: 14px;
                    line-height: 17.5px;
                    border: 1px solid $background-light-color;
                    border-radius: 4px;
                    padding: 9px 24px;

                    @media screen and (max-width: $tablet-m) {
                        bottom: 20px;
                        left: 20px;
                    }

                    @media screen and (max-width: $mobile-xl) {
                        bottom: 16px;
                        left: 16px;
                        font-size: 13px;
                        line-height: 16px;
                        padding: 8px 24px;
                    }

                    @media screen and (max-width: $mobile-m) {
                        font-size: 12px;
                        line-height: 12px;
                        padding: 8px 20px;
                    }
                }
            }
        }
    }

    .main__seo {
        display: flex;
        padding: 24px 80px;
        background: $background-seo-color;
        color: $text-light-color;
        font-family: $primary-font-family;

        @media screen and (max-width: $desktop-xs) {
            padding: 24px;
        }

        .main__seo__scrollarea {
            padding-right: 20px;
            height: 188px;
            overflow-y: scroll;
            scrollbar-width: thin;
            scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;

            h1 {
                display: inline-block;
                margin-bottom: 6px;
                padding-right: 40px;
                font-size: 14px;
                font-weight: $font-bolder;
                line-height: 16.8px;
                text-transform: uppercase;
            }

            h2 {
                font-size: 13px;
                font-weight: $font-bold;
                line-height: 17px;
            }

            p {
                margin-bottom: 21px;
                font-size: 11px;
                font-weight: $font-regular;
                line-height: 15.4px;
            }

            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .spinner {
        display: none;
        background: $background-dark-color;
        width: 100%;
        z-index: 1900;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: fixed;

        &.show {
            display: flex;
        }

        img {
            opacity: 1;
            transition: opacity 0.25s ease-in-out 0s;
            visibility: visible;
            width: 300px;
        }
    }
}
