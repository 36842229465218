*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  background: none;
  vertical-align: baseline;
  text-decoration: none;
}

html {
  position: relative;
  height: 100%;
  overflow: initial;
}
@media (hover: none) {
  html {
    overflow: initial;
  }
}

body {
  position: relative;
  height: 100%;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

a:hover, button:hover {
  cursor: pointer;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #111a29;
  overflow-x: hidden;
}
body .center__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
}
body main, body footer {
  transition: all 0.3s;
}
@media screen and (min-width: 1025px) {
  body main, body footer {
    padding-left: 84px;
  }
}
@media screen and (max-width: 480px) {
  body footer {
    padding-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  body:has(:is(.sidemenu.is__open)) main, body:has(:is(.sidemenu.is__open)) footer {
    padding-left: 254px;
    overflow-x: hidden;
  }
}

body, * {
  scrollbar-width: thin;
  scrollbar-color: #696969 #020105;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #111a29;
  z-index: 100;
}
header .app__panel {
  display: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 0;
  padding: 8px 16px;
  background-color: #182133;
}
header .app__panel.open {
  display: flex;
  height: max-content;
}
header .app__panel__title {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
header .app__panel__title img {
  display: flex;
  width: auto;
  height: 18px;
  margin-right: 8px;
}
header .app__panel__title span {
  display: flex;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
header .app__panel__link {
  display: flex;
  margin: 0 12px;
  padding: 8px;
  border: 1px solid #87F031;
  border-radius: 4px;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
}
header .app__panel__close {
  width: 16px;
  height: 16px;
}
header .center__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 11px 40px 11px 16px;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  min-height: 100%;
}
@media screen and (max-width: 1480px) {
  header .center__wrapper {
    padding: 12px 16px;
  }
}
@media screen and (max-width: 480px) {
  header .center__wrapper {
    padding: 6px 16px 6px 10px;
  }
}
header .center__wrapper .burger {
  display: flex;
  cursor: pointer;
  width: 48px;
  min-width: 48px;
  aspect-ratio: 1;
  margin: 0 22px 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: #172337;
  padding: 8px;
  border-radius: 4px;
}
@media screen and (max-width: 599px) {
  header .center__wrapper .burger {
    margin: 0 5px 0 0;
  }
}
@media screen and (max-width: 480px) {
  header .center__wrapper .burger {
    margin: 0 0 0 0;
    background-color: transparent;
  }
}
@media screen and (max-width: 360px) {
  header .center__wrapper .burger {
    min-width: 42px;
    width: 42px;
    height: 42px;
  }
}
header .center__wrapper .burger .burger__line {
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: #ffffff;
  transition: all 0.2s ease;
}
@media screen and (max-width: 599px) {
  header .center__wrapper .burger .burger__line {
    width: 32px;
    height: 2px;
  }
}
@media screen and (max-width: 360px) {
  header .center__wrapper .burger .burger__line {
    width: 26px;
  }
}
header .center__wrapper .burger .burger__line:nth-child(2) {
  margin: 7px 0;
}
@media screen and (max-width: 599px) {
  header .center__wrapper .burger .burger__line:nth-child(2) {
    margin: 8px 0;
  }
}
@media screen and (max-width: 360px) {
  header .center__wrapper .burger .burger__line:nth-child(2) {
    margin: 6px 0;
  }
}
header .center__wrapper .burger.is__open .burger__line {
  margin: 0;
  width: 35px;
}
@media screen and (max-width: 599px) {
  header .center__wrapper .burger.is__open .burger__line {
    transition: all 0.3s ease;
    width: 32px;
    height: 2px;
  }
}
@media screen and (max-width: 360px) {
  header .center__wrapper .burger.is__open .burger__line {
    width: 26px;
  }
}
header .center__wrapper .burger.is__open .burger__line:nth-child(1) {
  transform: rotate(-45deg) translate(-2px, 2px);
}
@media screen and (max-width: 360px) {
  header .center__wrapper .burger.is__open .burger__line:nth-child(1) {
    transform: rotate(-45deg) translate(-2px, 3px);
  }
}
header .center__wrapper .burger.is__open .burger__line:nth-child(2) {
  width: 0;
  transition: all 0s ease;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
}
header .center__wrapper .burger.is__open .burger__line:nth-child(3) {
  transform: rotate(45deg) translate(-1px, -2px);
}
@media screen and (max-width: 599px) {
  header .center__wrapper .burger.is__open .burger__line:nth-child(3) {
    transform: rotate(45deg) translate(0, -1px);
  }
}
header .center__wrapper .header__link__logo {
  position: relative;
  width: max-content;
  min-width: 105px;
  height: 40px;
  margin-right: 5px;
}
@media screen and (max-width: 1199px) {
  header .center__wrapper .header__link__logo {
    min-width: 86px;
    height: 36px;
  }
}
@media screen and (max-width: 414px) {
  header .center__wrapper .header__link__logo {
    min-width: 68px;
    height: 30px;
  }
}
@media screen and (max-width: 360px) {
  header .center__wrapper .header__link__logo {
    min-width: 60px;
    height: 36px;
  }
}
@media screen and (max-width: 320px) {
  header .center__wrapper .header__link__logo {
    min-width: 35px;
  }
}
header .center__wrapper .header__link__logo img {
  position: absolute;
  top: -5px;
}
@media screen and (max-width: 1199px) {
  header .center__wrapper .header__link__logo img {
    top: 0;
  }
}
@media screen and (max-width: 360px) {
  header .center__wrapper .header__link__logo img {
    top: 5px;
  }
}
header .center__wrapper .header__nav {
  flex: 1;
}
header .center__wrapper .header__nav.topmenu > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 1366px) {
  header .center__wrapper .header__nav.topmenu > ul {
    display: none;
  }
}
header .center__wrapper .header__nav.topmenu > ul li {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 12px;
}
@media screen and (max-width: 1800px) {
  header .center__wrapper .header__nav.topmenu > ul li {
    padding: 0;
  }
}
header .center__wrapper .header__nav.topmenu > ul li a,
header .center__wrapper .header__nav.topmenu > ul li div, header .center__wrapper .header__nav.topmenu > ul li > span {
  padding: 11px;
  border-radius: 8px;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 1600px) {
  header .center__wrapper .header__nav.topmenu > ul li a,
  header .center__wrapper .header__nav.topmenu > ul li div, header .center__wrapper .header__nav.topmenu > ul li > span {
    font-size: 14px;
  }
}
header .center__wrapper .header__nav.topmenu > ul li a:hover,
header .center__wrapper .header__nav.topmenu > ul li div:hover, header .center__wrapper .header__nav.topmenu > ul li > span:hover {
  background-color: #21264c;
}
header .center__wrapper .header__nav.topmenu > ul li a img,
header .center__wrapper .header__nav.topmenu > ul li div img, header .center__wrapper .header__nav.topmenu > ul li > span img {
  display: none;
}
header .center__wrapper .header__nav.topmenu > ul .gamification__title:after {
  transform: rotate(0);
  content: "";
  width: 10px;
  margin-left: 10px;
  height: 5px;
  background: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.599976 1L4.59998 5L8.59998 1' stroke='%23838486' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center center;
  transition: all 0.3s;
}
header .center__wrapper .header__nav.topmenu > ul .gamification__submenu {
  position: absolute;
  top: 53px;
  display: none;
  height: auto;
  width: 382px;
  border-radius: 0 0 8px 8px;
  background-color: #111a29;
  transition: all 0.3s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
header .center__wrapper .header__nav.topmenu > ul .gamification__submenu__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 30px;
  width: 100%;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s;
}
header .center__wrapper .header__nav.topmenu > ul .gamification__submenu__link a,
header .center__wrapper .header__nav.topmenu > ul .gamification__submenu__link div {
  display: flex;
  align-items: center;
  gap: 4px;
}
header .center__wrapper .header__nav.topmenu > ul .gamification__submenu__link a img,
header .center__wrapper .header__nav.topmenu > ul .gamification__submenu__link div img {
  height: 24px;
  width: 24px;
  display: block;
}
header .center__wrapper .header__nav.topmenu > ul .gamification:hover .gamification__submenu {
  opacity: 1;
  visibility: visible;
  display: block;
}
header .center__wrapper .header__nav.topmenu > ul .gamification:hover .gamification__title:after {
  transform: rotate(-180deg);
}
header .center__wrapper .header__nav.sidemenu .ul_wrapper {
  max-width: 84px;
  transition: all 0.3s;
}
header .center__wrapper .header__nav.sidemenu .ul_wrapper ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  scrollbar-width: none;
}
header .center__wrapper .header__nav.sidemenu .ul_wrapper ul li {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
header .center__wrapper .header__nav.sidemenu .ul_wrapper ul li .label {
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
header .center__wrapper .header__nav.sidemenu .ul_wrapper ul li .label.small {
  font-size: 7px;
  line-height: 8.53px;
}
header .center__wrapper .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header .dropdown-caret {
  transition: opacity 0.3s normal 0.15s;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 1024px) {
  header .center__wrapper .header__nav.sidemenu .ul_wrapper {
    left: -100%;
    visibility: hidden;
    opacity: 0;
  }
}
@media screen and (max-width: 1024px) {
  header .center__wrapper .header__nav.sidemenu .ul_wrapper {
    max-width: 100%;
  }
}
header .center__wrapper .header__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
header .center__wrapper .header__buttons .header__button__support {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}
@media screen and (max-width: 1024px) {
  header .center__wrapper .header__buttons .header__button__support {
    display: none;
  }
}
header .center__wrapper .header__buttons .header__button__support img {
  width: 24px;
  height: 24px;
}
header .center__wrapper .header__buttons .header__button__support:hover {
  background-color: #21264c;
}
header .center__wrapper .header__buttons .header__button__signup,
header .center__wrapper .header__buttons .header__button__login {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  text-align: center;
  box-shadow: none;
  transition: background-color 0.3s ease-in-out;
}
@media screen and (max-width: 480px) {
  header .center__wrapper .header__buttons .header__button__signup,
  header .center__wrapper .header__buttons .header__button__login {
    width: 80px;
    height: 29px;
    font-size: 10px;
    line-height: 12.5px;
  }
}
header .center__wrapper .header__buttons .header__button_green {
  background-color: #87f031;
  border: 1px solid #87f031;
  color: #2d2a26;
}
header .center__wrapper .header__buttons .header__button_green:hover {
  background-color: #8fe846;
}
header .center__wrapper .header__buttons .header__button_green-outlined {
  background-color: transparent;
  border: 1px solid #87f031;
  color: #ffffff;
}
header .center__wrapper .header__buttons .header__button_green-outlined:hover {
  background-color: rgba(135, 240, 49, 0.2);
}

@keyframes mobile-app-banner-slide-top {
  0% {
    transform: translate(-50%, 200px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.mobileAppBanner {
  display: none;
  position: fixed;
  width: calc(100% - 40px);
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  padding: 12px 46px 12px 12px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  line-height: 17px;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  overflow: hidden;
  animation: mobile-app-banner-slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  z-index: 999;
}

@media screen and (max-width: 599px) {
  .mobileAppBanner {
    display: flex;
    bottom: 75px;
    max-height: 60px;
  }
}
@media screen and (max-width: 480px) {
  .mobileAppBanner {
    display: flex;
    bottom: 65px;
    max-height: 60px;
  }
}
.mobileAppBanner_logo {
  display: flex;
}

.mobileAppBanner_text {
  display: flex;
  flex-direction: column;
  padding: 0 3px 0 8px;
  flex: auto;
}

.mobileAppBanner_title {
  font-family: "Montserrat", "Arial", sans-serif !important;
  font-weight: bold;
  font-size: 14px;
}

.mobileAppBanner_description {
  font-family: "Montserrat", "Arial", sans-serif !important;
  color: #929292;
  font-size: 10px;
}

.mobileAppBanner_button {
  font-family: "Arial", sans-serif !important;
  background: #87f031;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  text-transform: none;
  min-width: 0;
  min-height: 0;
  padding: 9px 12px;
}

.mobileAppBanner_close {
  position: absolute;
  right: 8px;
  top: 10px;
}

.mobileAppBanner_close span {
  display: flex;
}

.iconButton {
  margin-left: auto;
  z-index: 1;
  stroke: #999DAD;
}

.iconButton svg {
  stroke: #999DAD;
}

.iconButton:hover,
.iconButton:active {
  background: transparent !important;
}

.iconButton:hover svg,
.iconButton:active svg {
  stroke: #73788C;
}

@media (hover: none) {
  .iconButton {
    background: transparent !important;
    animation: none !important;
  }
}
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 4, 11, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 1280px) {
  .overlay.is__open {
    display: block;
  }
}

header .header__nav.topmenu ul {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1024px) {
  header .header__nav.topmenu ul {
    padding: 16px 16px 35px;
  }
}
@media screen and (max-width: 599px) {
  header .header__nav.topmenu ul {
    padding: 12px 16px 120px;
  }
}
header .header__nav.topmenu ul li {
  width: 100%;
}
header .header__nav.topmenu ul li a, header .header__nav.topmenu ul li > span {
  display: flex;
  align-items: center;
  padding: 6.5px 32px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  border-radius: 0;
  cursor: pointer;
}
@media screen and (max-width: 360px) {
  header .header__nav.topmenu ul li a, header .header__nav.topmenu ul li > span {
    font-size: 14px;
  }
}
header .header__nav.topmenu ul li a:hover, header .header__nav.topmenu ul li > span:hover {
  background-color: #172337;
  color: #eaeaea;
}
header .header__nav.topmenu ul li a img, header .header__nav.topmenu ul li > span img {
  display: none;
}

header .header__nav.sidemenu {
  flex: 0;
}
header .header__nav.sidemenu.is__open .ul_wrapper {
  max-width: 254px;
}
header .header__nav.sidemenu.is__open .ul_wrapper ul li .label {
  visibility: visible;
  opacity: 1;
}
header .header__nav.sidemenu.is__open .ul_wrapper ul li.accordion .accordion__header .dropdown-caret {
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 1024px) {
  header .header__nav.sidemenu.is__open .ul_wrapper {
    left: 0;
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 480px) {
  header .header__nav.sidemenu.is__open .ul_wrapper {
    max-width: 100%;
  }
}
header .header__nav.sidemenu:not(.is__open) .ul_wrapper ul li.accordion.open .accordion__body {
  max-height: 0 !important;
}
header .header__nav.sidemenu .ul_wrapper {
  position: fixed;
  top: 64px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 64px);
  scrollbar-width: none;
  background-color: #111A29;
  transform: translateX(-16px);
}
header .header__nav.sidemenu .ul_wrapper ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 0;
  min-height: 100%;
}
@media screen and (max-width: 1024px) {
  header .header__nav.sidemenu .ul_wrapper ul {
    padding: 16px 16px 35px;
  }
}
@media screen and (max-width: 599px) {
  header .header__nav.sidemenu .ul_wrapper ul {
    padding: 16px 16px 120px;
  }
}
header .header__nav.sidemenu .ul_wrapper ul .calendar {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  background: linear-gradient(180deg, #166C47 0%, #1B3B2F 100%) !important;
  position: relative;
}
header .header__nav.sidemenu .ul_wrapper ul .calendar a {
  background: none !important;
  z-index: 1;
}
header .header__nav.sidemenu .ul_wrapper ul .calendar .falling_stars_animation {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
header .header__nav.sidemenu .ul_wrapper ul .calendar .falling_stars_animation img {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  user-select: none;
  object-fit: cover;
  pointer-events: none;
  position: relative;
}
header .header__nav.sidemenu .ul_wrapper ul li {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 4px;
  cursor: pointer;
}
header .header__nav.sidemenu .ul_wrapper ul li > a, header .header__nav.sidemenu .ul_wrapper ul li > span, header .header__nav.sidemenu .ul_wrapper ul li > span > div {
  height: 39px;
  min-height: 39px;
}
header .header__nav.sidemenu .ul_wrapper ul li > a:hover, header .header__nav.sidemenu .ul_wrapper ul li > span:hover, header .header__nav.sidemenu .ul_wrapper ul li > span > div:hover {
  background: linear-gradient(90deg, #002E7C 0%, #172337 100%);
}
@media screen and (max-width: 480px) {
  header .header__nav.sidemenu .ul_wrapper ul li > a, header .header__nav.sidemenu .ul_wrapper ul li > span, header .header__nav.sidemenu .ul_wrapper ul li > span > div {
    height: 46px;
    min-height: 46px;
  }
}
header .header__nav.sidemenu .ul_wrapper ul li > a, header .header__nav.sidemenu .ul_wrapper ul li > span, header .header__nav.sidemenu .ul_wrapper ul li > span > div,
header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > a,
header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > span,
header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  width: 100%;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #172337;
  transition: none;
}
@media screen and (max-width: 360px) {
  header .header__nav.sidemenu .ul_wrapper ul li > a, header .header__nav.sidemenu .ul_wrapper ul li > span, header .header__nav.sidemenu .ul_wrapper ul li > span > div,
  header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > a,
  header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > span,
  header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > div {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  header .header__nav.sidemenu .ul_wrapper ul li > a, header .header__nav.sidemenu .ul_wrapper ul li > span, header .header__nav.sidemenu .ul_wrapper ul li > span > div,
  header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > a,
  header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > span,
  header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > div {
    height: 46px;
    min-height: 46px;
  }
}
header .header__nav.sidemenu .ul_wrapper ul li > a img, header .header__nav.sidemenu .ul_wrapper ul li > span img, header .header__nav.sidemenu .ul_wrapper ul li > span > div img,
header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > a img,
header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > span img,
header .header__nav.sidemenu .ul_wrapper ul li .accordion__body > div img {
  display: block;
  width: 20px;
  height: auto;
}
header .header__nav.sidemenu .ul_wrapper ul li > span > div {
  padding: 0;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion {
  background: #172337;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header {
  background: #172337;
  border-radius: 4px;
  transition: none;
  position: relative;
  height: 39px;
  min-height: 39px;
}
@media (max-width: 480px) {
  header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header {
    height: 46px;
    min-height: 46px;
  }
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header:hover {
  background: linear-gradient(90deg, #002E7C 0%, #172337 100%);
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header > div {
  display: flex;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header .dropdown-caret {
  position: absolute;
  right: 8px;
  background-color: #1C2B43;
  transition: all 0.2s;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__header .dropdown-caret img {
  height: 13px;
  user-select: none;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease-out;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > a, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > span, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > div {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 0 16px;
  height: 36px;
  min-height: 36px;
  text-transform: capitalize;
  border-radius: 4px;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > a:hover, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > span:hover, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > div:hover {
  background-color: #1F2F4A;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > a:last-child, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > span:last-child, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > div:last-child {
  border-radius: 0 0 4px 4px;
  text-transform: none;
}
@media screen and (max-width: 480px) {
  header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > a, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > span, header .header__nav.sidemenu .ul_wrapper ul li.accordion .accordion__body > div {
    height: 44px;
    min-height: 44px;
  }
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion.open .accordion__header {
  background: #1F2F4A;
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion.open .accordion__header .dropdown-caret {
  transform: rotate(180deg);
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion.open .accordion__header:hover {
  background: linear-gradient(90deg, #002E7C 0%, #172337 100%);
}
header .header__nav.sidemenu .ul_wrapper ul li.accordion.open .accordion__body {
  max-height: max-content;
}
@media screen and (max-width: 480px) {
  header .header__nav.sidemenu .ul_wrapper {
    top: 58px;
    max-width: unset;
    height: calc(100% - 58px);
  }
}
@media screen and (max-width: 360px) {
  header .header__nav.sidemenu .ul_wrapper {
    top: 54px;
    height: calc(100% - 54px);
  }
}

header:has(#app__panel.open) .sidemenu .ul_wrapper {
  height: calc(100% - 109px);
  top: 109px;
}
@media screen and (max-width: 480px) {
  header:has(#app__panel.open) .sidemenu .ul_wrapper {
    height: calc(100% - 105px);
    top: 105px;
  }
}
@media screen and (max-width: 360px) {
  header:has(#app__panel.open) .sidemenu .ul_wrapper {
    height: calc(100% - 99px);
    top: 99px;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  min-height: 100dvh;
}
@media screen and (max-width: 480px) {
  main {
    padding-top: 48px;
  }
}
main .main__choices {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  max-width: 1330px;
  margin: 0 auto;
}
@media screen and (max-width: 1366px) {
  main .main__choices {
    max-width: 90vw;
  }
}
@media screen and (max-width: 1199px) {
  main .main__choices {
    padding: 0 16px;
    max-width: 100%;
  }
}
@media screen and (max-width: 899px) {
  main .main__choices {
    flex-direction: column;
  }
}
@media screen and (max-width: 480px) {
  main .main__choices {
    padding: 0 10px;
  }
}
main .main__choices article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 12px);
  border-radius: 8px;
  overflow: hidden;
  background-color: #0b233d;
}
@media screen and (max-width: 899px) {
  main .main__choices article {
    width: 100%;
    border-radius: 0;
    background-color: transparent;
  }
}
main .main__choices article .main__choices__img-wrapper {
  width: auto;
  height: 240px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  main .main__choices article .main__choices__img-wrapper {
    width: 100%;
    height: auto;
  }
}
main .main__choices article .main__choices__img-wrapper picture {
  display: inline;
}
main .main__choices article .main__choices__img-wrapper picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 899px) {
  main .main__choices article .main__choices__img-wrapper picture img {
    border-radius: 8px;
  }
}
main .main__choices article h1 {
  margin: 18px auto 24px;
  max-width: 560px;
  height: 64px;
  color: #ffffff;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  white-space: pre-line;
  cursor: pointer;
}
@media screen and (max-width: 899px) {
  main .main__choices article h1 {
    margin: 16px auto;
    text-align: center;
    max-width: 460px;
  }
}
@media screen and (max-width: 448px) {
  main .main__choices article h1:not(:has(br)) {
    height: 96px;
  }
}
main .main__choices article button {
  padding: 16px;
  margin-bottom: 24px;
  width: auto;
  height: 54px;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  box-shadow: none;
  background-color: #87f031;
  border: 1px solid #87f031;
  color: #2d2a26;
  text-transform: uppercase;
}
@media screen and (max-width: 899px) {
  main .main__choices article button {
    margin-bottom: 0;
    width: 100%;
    height: 45px;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
  }
}
main .main__choices article button.hidden {
  display: none;
}
main .main__vip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 42px 0;
  width: 100%;
  max-width: 1330px;
  height: max-content;
}
@media screen and (max-width: 899px) {
  main .main__vip {
    margin-top: 23px;
  }
}
@media screen and (max-width: 480px) {
  main .main__vip {
    margin: 40px 0 30px;
  }
}
main .main__vip a {
  position: relative;
  display: flex;
  margin: 0 auto;
  padding: 60px 33px;
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(135deg, #262d3b 4.86%, #2a3243 54.91%, #1f2531);
}
@media screen and (max-width: 1366px) {
  main .main__vip a {
    max-width: 90vw;
    height: 300px;
    padding: 52px 33px;
  }
}
@media screen and (max-width: 1199px) {
  main .main__vip a {
    height: 250px;
    max-width: calc(100% - 32px);
    margin: 0 16px;
    padding: 36px 33px;
  }
}
@media screen and (max-width: 899px) {
  main .main__vip a {
    height: 223px;
    padding: 33px;
  }
}
@media screen and (max-width: 700px) {
  main .main__vip a {
    height: 180px;
  }
}
@media screen and (max-width: 599px) {
  main .main__vip a {
    padding: 38px 16px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 480px) {
  main .main__vip a {
    height: 150px;
    padding: 24px 16px;
    max-width: calc(100% - 20px);
    margin: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  main .main__vip a {
    padding: 18px 16px;
  }
}
main .main__vip a .main__vip__textarea {
  display: flex;
  flex-direction: column;
  max-width: 465px;
}
main .main__vip a .main__vip__textarea h1 {
  margin-bottom: 13px;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background: linear-gradient(100deg, #f1cf9f, #f0cd9b 0.01%, #dca557);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-size: 100%;
  color: #2d2a26;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: -0.015em;
  text-align: left;
  text-transform: uppercase;
}
@media screen and (max-width: 1366px) {
  main .main__vip a .main__vip__textarea h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 1199px) {
  main .main__vip a .main__vip__textarea h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 899px) {
  main .main__vip a .main__vip__textarea h1 {
    max-width: 365px;
    margin-bottom: 12px;
    font-size: 28px;
  }
}
@media screen and (max-width: 700px) {
  main .main__vip a .main__vip__textarea h1 {
    max-width: 220px;
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  main .main__vip a .main__vip__textarea h1 {
    max-width: 175px;
    margin-bottom: 12px;
    font-size: 16px;
  }
}
main .main__vip a .main__vip__textarea h2 {
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.7019607843);
  transition: text-decoration-color 0.3s ease-in-out;
}
@media screen and (max-width: 1024px) {
  main .main__vip a .main__vip__textarea h2 {
    min-height: 40px;
    font-size: 16px;
  }
}
@media screen and (max-width: 700px) {
  main .main__vip a .main__vip__textarea h2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 599px) {
  main .main__vip a .main__vip__textarea h2 {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  main .main__vip a .main__vip__textarea h2 {
    max-width: 150px;
  }
}
main .main__vip a img {
  position: absolute;
  max-height: 344px;
  right: -3%;
  top: 50%;
  width: auto;
  height: 105%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (max-width: 1480px) {
  main .main__vip a img {
    right: -1%;
  }
}
@media screen and (max-width: 1366px) {
  main .main__vip a img {
    max-height: 280px;
  }
}
@media screen and (max-width: 1199px) {
  main .main__vip a img {
    max-height: 240px;
  }
}
@media screen and (max-width: 1024px) {
  main .main__vip a img {
    max-height: 225px;
  }
}
@media screen and (max-width: 899px) {
  main .main__vip a img {
    max-height: 23vw;
  }
}
@media screen and (max-width: 700px) {
  main .main__vip a img {
    max-height: 28vw;
  }
}
@media screen and (max-width: 599px) {
  main .main__vip a img {
    max-height: 27vw;
  }
}
@media screen and (max-width: 480px) {
  main .main__vip a img {
    max-height: 25vw;
  }
}
@media screen and (max-width: 360px) {
  main .main__vip a img {
    max-height: 22vw;
  }
}
main .main__vip a:hover .main__vip__textarea h2 {
  text-decoration-color: transparent;
}
main .slider__section {
  height: max-content;
  overflow: hidden;
}
main .slider__section .slider__title {
  height: 50px;
  text-align: center;
  margin-bottom: 16px;
  font-size: 36px;
  line-height: 50px;
  color: #ffffff;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 899px) {
  main .slider__section .slider__title {
    height: 36px;
    font-size: 26px;
    line-height: 36px;
  }
}
@media screen and (max-width: 480px) {
  main .slider__section .slider__title {
    margin-bottom: 12px;
    height: 25px;
    font-size: 18px;
    line-height: 25px;
  }
}
main .main__slider {
  margin: 0 24px 24px;
  width: calc(100% - 48px);
}
@media screen and (max-width: 1280px) {
  main .main__slider {
    margin: 0 25% 24px;
    width: 50%;
  }
}
@media screen and (max-width: 899px) {
  main .main__slider {
    margin: 0 11% 44px;
    width: 78%;
    height: 100%;
  }
}
@media screen and (max-width: 480px) {
  main .main__slider {
    margin: 0 5% 12px;
    width: 90%;
  }
}
main .main__slider .slider__wrapper {
  width: 100%;
  position: relative;
  margin-left: calc(-16px * (var(--i, 1)));
}
@media screen and (max-width: 1280px) {
  main .main__slider .slider__wrapper {
    margin-left: 0;
  }
}
@media screen and (max-width: 899px) {
  main .main__slider .slider__wrapper {
    height: 100%;
  }
}
main .main__slider .slider__wrapper_left, main .main__slider .slider__wrapper_right {
  display: block;
  position: absolute;
  width: 8px;
  height: 100%;
  background-color: #111a29;
  top: 0;
  z-index: 1;
}
@media screen and (max-width: 1280px) {
  main .main__slider .slider__wrapper_left, main .main__slider .slider__wrapper_right {
    display: none;
  }
}
main .main__slider .slider__wrapper_left {
  left: calc(-24px + 16px * (var(--i, 1)));
}
main .main__slider .slider__wrapper_right {
  right: calc(-24px - 16px * (var(--i, 1)));
}
main .main__slider .slider {
  display: flex;
  align-items: center;
  gap: 16px;
  overflow-y: hidden;
  --n: 1;
  width: 100%;
  width: calc(var(--n) * 100%);
  transform: translate(calc(var(--tx, 0px) + var(--i, 1) / var(--n) * -100%));
}
@media screen and (max-width: 1280px) {
  main .main__slider .slider {
    width: 100%;
    width: calc(var(--n) * 100%);
    transform: translate(calc(var(--tx, 5px) + var(--i, 2) / var(--n) * -100%));
  }
}
main .main__slider .slider.smooth {
  transition: transform 0.5s ease-out;
}
main .main__slider .slider .slide {
  width: calc(100% / (var(--n) * 3) - 10.6666666667px);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 1280px) {
  main .main__slider .slider .slide {
    width: calc(100% / (var(--n)) - 10.6666666667px);
  }
}
@media screen and (max-width: 899px) {
  main .main__slider .slider .slide {
    height: 100%;
  }
}
main .main__slider .slider .slide img {
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: 100%;
}
main .main__slider .slider .slide button {
  position: absolute;
  bottom: 24px;
  left: 24px;
  font-family: Montserrat, sans-serif;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.5px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 9px 24px;
}
@media screen and (max-width: 599px) {
  main .main__slider .slider .slide button {
    bottom: 20px;
    left: 20px;
  }
}
@media screen and (max-width: 480px) {
  main .main__slider .slider .slide button {
    bottom: 16px;
    left: 16px;
    font-size: 13px;
    line-height: 16px;
    padding: 8px 24px;
  }
}
@media screen and (max-width: 400px) {
  main .main__slider .slider .slide button {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 20px;
  }
}
main .main__seo {
  display: flex;
  padding: 24px 80px;
  background: #0e1622;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
}
@media screen and (max-width: 1199px) {
  main .main__seo {
    padding: 24px;
  }
}
main .main__seo .main__seo__scrollarea {
  padding-right: 20px;
  height: 188px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #696969 #020105;
}
main .main__seo .main__seo__scrollarea h1 {
  display: inline-block;
  margin-bottom: 6px;
  padding-right: 40px;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-transform: uppercase;
}
main .main__seo .main__seo__scrollarea h2 {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
}
main .main__seo .main__seo__scrollarea p {
  margin-bottom: 21px;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
}
main .main__seo .main__seo__scrollarea *:last-child {
  margin-bottom: 0;
}
main .spinner {
  display: none;
  background: #111a29;
  width: 100%;
  z-index: 1900;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}
main .spinner.show {
  display: flex;
}
main .spinner img {
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0s;
  visibility: visible;
  width: 300px;
}

footer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 40px 100px;
}
@media screen and (max-width: 1480px) {
  footer {
    padding: 24px 16px 100px;
  }
}
@media screen and (max-width: 1366px) {
  footer {
    padding: 24px 16px 40px;
  }
}
@media screen and (max-width: 1199px) {
  footer {
    gap: 20px;
  }
}
@media screen and (max-width: 700px) {
  footer {
    padding: 24px 10px 85px;
  }
}
@media screen and (max-width: 599px) {
  footer {
    gap: 12px;
  }
}
footer .center__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  gap: 24px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper {
    gap: 16px;
  }
}
@media screen and (max-width: 599px) {
  footer .center__wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper {
    gap: 0;
  }
}
@media screen and (orientation: landscape) {
  footer .center__wrapper {
    flex-direction: row;
  }
}
footer .center__wrapper .footer__nav {
  display: flex;
  flex-direction: row;
  flex-basis: 65%;
  gap: 24px;
  width: 100%;
}
@media screen and (max-width: 1800px) {
  footer .center__wrapper .footer__nav {
    flex-basis: 100%;
    border-bottom: 1px solid #2f3b52;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__nav {
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    flex-basis: 100%;
    gap: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__nav {
    flex-direction: column;
    gap: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item {
  display: flex;
  flex-direction: column;
  flex: 1 0 13%;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__nav .footer__nav__item {
    flex: 1 1 13%;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__nav .footer__nav__item {
    margin-bottom: 12px;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item:nth-child(6) {
  margin-right: 16px;
}
@media screen and (max-width: 1800px) {
  footer .center__wrapper .footer__nav .footer__nav__item:nth-child(6) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1280px) {
  footer .center__wrapper .footer__nav .footer__nav__item:nth-child(6) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__nav .footer__nav__item:nth-child(6) {
    margin-right: 0;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__title {
  margin-bottom: 12px;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 29px;
  text-transform: capitalize;
}
@media screen and (max-width: 1199px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__title {
    margin-bottom: 21px;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__title {
    margin: 14px 0;
    font-size: 14px;
    line-height: 1;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list {
  display: flex;
  flex-direction: column;
  padding-bottom: 21px;
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list {
    padding-bottom: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 12px;
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list {
    column-gap: 0;
  }
}
@media screen and (max-width: 768px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list {
    width: calc(100% - 16px);
    margin: 0 auto;
  }
}
@media screen and (max-width: 599px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list {
    padding: 0 16px;
  }
}
@media screen and (max-width: 414px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list {
    padding: 0 20px;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1199px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li {
    padding-bottom: 21px;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li {
    padding: 0 8px 10px;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li a, footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li span {
  width: 100%;
  color: #b1c3e5;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  text-decoration: underline;
  text-decoration-color: transparent;
  white-space: nowrap;
  transition: text-decoration-color 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 1199px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li a, footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li span {
    font-size: 12px;
    line-height: 12px;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li a, footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li span {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li a, footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li span {
    width: max-content;
  }
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li a:hover, footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list li span:hover {
  text-decoration-color: #b1c3e5;
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list .footer__nav__whatsapp a {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list .footer__nav__whatsapp a p {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease-in-out;
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list .footer__nav__whatsapp a:hover p {
  text-decoration-color: #b1c3e5;
}
footer .center__wrapper .footer__nav .footer__nav__item .footer__nav__list .footer__nav__whatsapp a span {
  font-size: 9px;
  font-weight: 300;
}
footer .center__wrapper .footer__links {
  border-bottom: none;
  flex: 1 0 13%;
}
@media screen and (max-width: 1800px) {
  footer .center__wrapper .footer__links {
    flex: 1 1 34.3%;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__links {
    flex: 1 1 50%;
    flex-direction: row;
    gap: 24px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__links {
    flex-direction: column;
    flex: 0 0 33%;
    gap: 16px;
    padding-right: 24px;
    border-right: 1px solid #2f3b52;
    height: 270px;
  }
}
@media screen and (max-width: 768px) {
  footer .center__wrapper .footer__links {
    gap: 20px;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links {
    padding-right: 0;
    border-right: none;
    flex: 1;
    height: auto;
    gap: 0;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links .footer__nav__item {
    border-top: 1px solid #2f3b52;
  }
}
footer .center__wrapper .footer__links .footer__nav__item__social {
  min-width: 205px;
  flex: 1 0 18%;
}
@media screen and (max-width: 1800px) {
  footer .center__wrapper .footer__links .footer__nav__item__social {
    min-width: initial;
    flex: 1 0 14%;
  }
}
@media screen and (max-width: 1199px) {
  footer .center__wrapper .footer__links .footer__nav__item .footer__nav__title {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__links .footer__nav__item .footer__nav__title {
    margin: 0 0 12px;
    font-size: 14px;
    line-height: 1;
    text-align: left;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links .footer__nav__item .footer__nav__title {
    padding-top: 12px;
    text-align: center;
  }
}
footer .center__wrapper .footer__links .footer__logos {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links .footer__logos {
    justify-content: center;
  }
}
footer .center__wrapper .footer__links .footer__logos .footer__logos__regulator {
  width: 98px;
  height: 24px;
  object-fit: cover;
}
footer .center__wrapper .footer__links .footer__logos .footer__logos__age {
  display: flex;
  min-width: 32px;
  min-height: 32px;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1.5px solid #e10000;
  border-radius: 50%;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 18.1818px 36.3636px rgba(0, 0, 0, 0.25);
}
footer .center__wrapper .footer__links .footer__apps {
  display: flex;
  width: 152px;
  flex-direction: column;
  gap: 8px;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__links .footer__apps {
    width: 110px;
    flex-direction: row;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links .footer__apps {
    margin: 0 auto;
    width: auto;
  }
}
footer .center__wrapper .footer__links .footer__apps img {
  display: inline-flex;
  align-items: center;
  height: 45px;
  cursor: pointer;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__links .footer__apps img {
    height: 32px;
  }
}
footer .center__wrapper .footer__links .footer__socials {
  display: grid;
  grid-template-columns: repeat(3, 42px);
  gap: 8px;
}
footer .center__wrapper .footer__links .footer__socials__default {
  margin-bottom: 20px;
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links .footer__socials__default {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__links .footer__socials {
    grid-template-columns: repeat(3, 26px);
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__links .footer__socials {
    place-content: center;
  }
}
footer .center__wrapper .footer__links .footer__socials .footer__social {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background: #2f3b52;
  border-radius: 5px;
  cursor: pointer;
}
footer .center__wrapper .footer__links .footer__socials .footer__social img {
  width: 23px;
  height: 23px;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__links .footer__socials .footer__social {
    height: 26px;
  }
  footer .center__wrapper .footer__links .footer__socials .footer__social img {
    width: 13px;
    height: 13px;
  }
}
footer .center__wrapper .award__logo__wrapper {
  position: absolute;
  right: 82px;
  top: 44%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media screen and (max-width: 1800px) {
  footer .center__wrapper .award__logo__wrapper {
    position: static;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #2f3b52;
  }
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .award__logo__wrapper {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .award__logo__wrapper {
    display: none;
  }
}
footer .center__wrapper .award__logo__wrapper__tablet {
  display: none;
  border-top: none;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .award__logo__wrapper__tablet {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 15%;
    justify-content: center;
    align-items: end;
    margin-top: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .award__logo__wrapper__tablet {
    display: none;
  }
}
footer .center__wrapper .award__logo__wrapper__mobile.award__logo__wrapper__mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .award__logo__wrapper__mobile.award__logo__wrapper__mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    text-align: center;
    border-left: none;
    padding-top: 12px;
    gap: 14px;
    flex-direction: row;
  }
}
footer .center__wrapper .award__logo {
  width: 107px;
  height: 78.7px;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .award__logo {
    width: 131px;
    height: 98px;
  }
}
footer .center__wrapper .footer__payments {
  flex-basis: 100%;
}
@media screen and (max-width: 1800px) {
  footer .center__wrapper .footer__payments {
    flex: 1 0 0;
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__payments {
    justify-content: center;
    flex-direction: column;
    flex: 0 0 33%;
    padding-right: 24px;
    padding-left: 24px;
    border-right: 1px solid #2f3b52;
    height: 270px;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__payments {
    flex: 0 0 100%;
    margin-bottom: 0;
    padding-top: 12px;
    padding-right: 0;
    padding-left: 0;
    border-top: 1px solid #2f3b52;
    border-right: none;
    height: auto;
  }
}
@media screen and (max-width: 599px) {
  footer .center__wrapper .footer__payments {
    flex: 0;
  }
}
footer .center__wrapper .footer__payments .footer__payments__title {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  line-height: 29px;
  text-transform: capitalize;
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__payments .footer__payments__title {
    font-size: 14px;
    line-height: 1;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__payments .footer__payments__title {
    text-align: center;
  }
}
footer .center__wrapper .footer__payments .footer__payments__options {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50px);
  gap: 8px;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  footer .center__wrapper .footer__payments .footer__payments__options {
    grid-template-columns: repeat(4, 47px);
  }
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__payments .footer__payments__options {
    grid-template-columns: repeat(5, 47px);
  }
}
@media screen and (max-width: 768px) {
  footer .center__wrapper .footer__payments .footer__payments__options {
    grid-template-columns: repeat(4, 47px);
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__payments .footer__payments__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
  }
}
footer .center__wrapper .footer__payments .footer__payments__options a {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  border-radius: 4px;
  background-color: #ffffff;
}
footer .center__wrapper .footer__payments .footer__payments__options a img {
  max-width: 75%;
  height: 25px;
  object-fit: contain;
}
@media screen and (max-width: 899px) {
  footer .center__wrapper .footer__payments .footer__payments__options a {
    width: 100%;
    max-width: 47px;
  }
}
@media screen and (max-width: 700px) {
  footer .center__wrapper .footer__payments .footer__payments__options a {
    max-width: 48px;
    margin: 0 4px 8px;
    border-radius: 2px;
  }
}
footer .footer__info {
  width: 100%;
  font-family: Montserrat, sans-serif;
  color: #ffffff;
}
@media screen and (max-width: 1800px) {
  footer .footer__info {
    padding-top: 16px;
    border-top: 1px solid #2f3b52;
  }
}
@media screen and (max-width: 700px) {
  footer .footer__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
  }
}
footer .footer__info .footer__info__title {
  font-size: 13px;
  line-height: 29px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 12px;
  position: relative;
}
@media screen and (max-width: 899px) {
  footer .footer__info .footer__info__title {
    text-align: left;
  }
}
@media screen and (max-width: 700px) {
  footer .footer__info .footer__info__title {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 8px;
  }
}
footer .footer__info .footer__info__container {
  width: 100%;
  font-weight: 300;
  font-size: 11px;
  line-height: 18px;
}
@media screen and (max-width: 599px) {
  footer .footer__info .footer__info__container {
    text-align: center;
  }
}
footer .footer__info .footer__info__container p:not(:last-of-type) {
  margin-bottom: 16px;
}
@media screen and (max-width: 599px) {
  footer .footer__info.accordion .footer__info__title {
    cursor: pointer;
  }
  footer .footer__info.accordion .footer__info__title:after {
    content: "";
    width: 20px;
    height: 10px;
    transform: rotate(180deg);
    transition: all 0.2s ease-out;
    background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 6.21484L8.00008 10.8815L3.33342 6.21484' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
  }
  footer .footer__info.accordion .footer__info__container {
    max-height: 1000px;
    overflow: hidden;
    transition: all 0.2s ease-out;
  }
}
@media screen and (max-width: 599px) {
  footer .footer__info.accordion.collapsed .footer__info__title::after {
    transform: rotate(0deg);
  }
  footer .footer__info.accordion.collapsed .footer__info__container {
    max-height: 0;
  }
}

[data-popup] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
  height: 100%;
  font-family: Montserrat, sans-serif;
  overflow: auto;
  z-index: -1;
}
[data-popup].is__open {
  display: flex;
  background-color: rgba(4, 4, 11, 0.8);
  scrollbar-width: none;
  overflow: auto;
  z-index: 1000;
}
@media screen and (max-width: 480px) {
  [data-popup].is__open {
    padding: 0;
  }
}
[data-popup] .popup__container {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 414px;
  width: 100%;
  height: fit-content;
  min-height: auto;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 480px) {
  [data-popup] .popup__container {
    min-height: 100%;
    max-width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}
[data-popup] .popup__container .popup__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 14px;
  width: 100%;
  height: 44px;
}
[data-popup] .popup__container .popup__header:has(> *:only-child) {
  justify-content: flex-end;
}
[data-popup] .popup__container .popup__header [alt=close] {
  transition: all 0.3s ease-in-out;
}
[data-popup] .popup__container .popup__header [alt=close]:hover {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  stroke: #73788c;
}
[data-popup] .popup__container .popup__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 16px 38px;
}
[data-popup] .popup__container .popup__content .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  width: 100%;
  height: 40px;
}
[data-popup] .popup__container .popup__content .icon img {
  width: auto;
  height: 100%;
}
[data-popup] .popup__container .popup__content form .input__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  [data-popup] .popup__container .popup__content form .input__row {
    margin-bottom: 10px;
  }
}
[data-popup] .popup__container .popup__content form .input__row label {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  color: #2d2a26;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  text-align: left;
}
[data-popup] .popup__container .popup__content form .input__row label span {
  font-size: 10px;
  font-weight: 400;
}
[data-popup] .popup__container .popup__content form .input__row input {
  display: block;
  padding: 23px 8px;
  max-width: 100%;
  width: 100%;
  max-height: 30px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #9ca4bf;
  background: #f5f6fa;
  color: #2d2a26;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 125%;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
[data-popup] .popup__container .popup__content form .input__row input::placeholder {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
  color: #999dad;
}
[data-popup] .popup__container .popup__content form .input__row input:focus {
  outline: none;
}
[data-popup] .popup__container .popup__content form .input__row .hidden-input {
  display: none;
}
[data-popup] .popup__container .popup__content form #signup__row__email {
  position: relative;
  width: 100%;
}
[data-popup] .popup__container .popup__content form #signup__row__email .select__item {
  text-align: left;
  padding: 1px 8px;
}
[data-popup] .popup__container .popup__content form .checkbox__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  [data-popup] .popup__container .popup__content form .checkbox__row {
    margin-bottom: 16px;
  }
}
[data-popup] .popup__container .popup__content form .checkbox__row .checkbox__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
[data-popup] .popup__container .popup__content form .checkbox__row .checkbox__wrapper input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #2d2a26;
  box-sizing: border-box;
  border-radius: 2px;
}
[data-popup] .popup__container .popup__content form .checkbox__row .checkbox__wrapper input[type=checkbox]:before {
  top: 0;
  width: 18px;
  height: 18px;
  content: "";
}
[data-popup] .popup__container .popup__content form .checkbox__row .checkbox__wrapper input[type=checkbox]:checked:before {
  background: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99804 0.488323C6.94953 0.439419 6.89183 0.400602 6.82824 0.374112C6.76466 0.347622 6.69646 0.333984 6.62758 0.333984C6.5587 0.333984 6.4905 0.347622 6.42692 0.374112C6.36334 0.400602 6.30563 0.439419 6.25713 0.488323L2.70283 4.0474L1.06968 2.40903C1.01932 2.36039 0.959869 2.32213 0.894724 2.29646C0.829578 2.27079 0.760013 2.2582 0.690001 2.25941C0.61999 2.26062 0.550902 2.27561 0.486684 2.30352C0.422465 2.33143 0.364373 2.37172 0.315723 2.42208C0.267074 2.47244 0.228821 2.53189 0.203148 2.59704C0.177475 2.66218 0.164884 2.73175 0.166095 2.80176C0.167307 2.87177 0.182296 2.94086 0.210207 3.00508C0.238119 3.0693 0.278406 3.12739 0.328768 3.17604L2.33237 5.17964C2.38087 5.22854 2.43858 5.26736 2.50217 5.29385C2.56575 5.32034 2.63395 5.33398 2.70283 5.33398C2.77171 5.33398 2.83991 5.32034 2.90349 5.29385C2.96707 5.26736 3.02478 5.22854 3.07328 5.17964L6.99804 1.25533C7.051 1.20647 7.09327 1.14717 7.12218 1.08116C7.15109 1.01516 7.16602 0.943883 7.16602 0.871825C7.16602 0.799768 7.15109 0.728491 7.12218 0.662487C7.09327 0.596483 7.051 0.537183 6.99804 0.488323Z' fill='%232D2A26'/%3E%3C/svg%3E%0A") no-repeat center center;
  background-size: 75% 75%;
}
[data-popup] .popup__container .popup__content form .checkbox__row .checkbox__wrapper label {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}
[data-popup] .popup__container .popup__content form .error__message,
[data-popup] .popup__container .popup__content form .signup__birthdate__row .error-message,
[data-popup] .popup__container .popup__content form .signup-submit-error {
  color: #d43b3b;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-top: 4px;
}
[data-popup] .popup__container .popup__content form .error__message.signup-phone,
[data-popup] .popup__container .popup__content form .signup__birthdate__row .error-message.signup-phone,
[data-popup] .popup__container .popup__content form .signup-submit-error.signup-phone {
  margin-left: 80px;
}
[data-popup] .popup__container .popup__content form .error__message#user__sms__code__error,
[data-popup] .popup__container .popup__content form .signup__birthdate__row .error-message#user__sms__code__error,
[data-popup] .popup__container .popup__content form .signup-submit-error#user__sms__code__error {
  text-align: center;
}
[data-popup] .popup__container .popup__content form .divider {
  position: relative;
  width: 100%;
  margin: 12px 0;
  border-top: 0.5px solid #cbc7c7;
}
@media screen and (max-width: 480px) {
  [data-popup] .popup__container .popup__content form .divider {
    margin: 10px 0 13px;
  }
}
[data-popup] .popup__container .popup__content form .agreement {
  display: inline;
  color: #000000;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  [data-popup] .popup__container .popup__content form .agreement {
    font-size: 12px;
  }
}
@media screen and (max-width: 480px) {
  [data-popup] .popup__container .popup__content form .agreement {
    font-size: 9px;
    line-height: 1.8;
  }
}
[data-popup] .popup__container .popup__content form .agreement a {
  display: inline;
  color: #000000;
  text-decoration: underline;
}
[data-popup] .popup__container .popup__content .auth-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
}
[data-popup] .popup__container .popup__content .auth-container.hide {
  display: none;
}
[data-popup] .popup__container .popup__content .auth-container .auth-separator {
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 16.5px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
}
[data-popup] .popup__container .popup__content .auth-container .auth-separator .auth-line {
  flex: 1;
  height: 1px;
  background-color: #E5E5EB;
}
[data-popup] .popup__container .popup__content .auth-container .auth-socials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
[data-popup] .popup__container .popup__content .auth-container .auth-socials .auth-social-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F3F6;
  height: 48px;
  padding: 12px;
  border-radius: 4px;
  transition: all 0.3s;
}
[data-popup] .popup__container .popup__content .auth-container .auth-socials .auth-social-btn:hover {
  background-color: #EBEDEF;
}

.popup__content h1 {
  margin-bottom: 24px;
  width: 100%;
  color: #2d2a26;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .popup__content h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .popup__content h1 {
    margin-bottom: 13px;
    font-size: 18px;
  }
}
.popup__content form#login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.popup__content form#login-form .login__input__wrapper {
  position: relative;
}
.popup__content form#login-form .login__input__wrapper input {
  padding-right: 50px;
}
.popup__content form#login-form .login__input__wrapper [data-view-password] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: max-content;
  height: 100%;
}
.popup__content form#login-form .login__input__wrapper [data-view-password].hide__password img:first-child {
  display: flex;
}
.popup__content form#login-form .login__input__wrapper [data-view-password].hide__password img:last-child {
  display: none;
}
.popup__content form#login-form .login__input__wrapper [data-view-password].show__password img:first-child {
  display: none;
}
.popup__content form#login-form .login__input__wrapper [data-view-password].show__password img:last-child {
  display: flex;
}
.popup__content form#login-form .open__forgotPass {
  display: block;
  color: #2826a5;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #2826a5;
  text-decoration-thickness: 0.2px;
  transition: text-decoration-color 0.3s ease-in-out;
  cursor: pointer;
}
.popup__content form#login-form .open__forgotPass:hover {
  text-decoration-color: transparent;
}
.popup__content form#login-form .login__submit {
  width: 100%;
  height: 54px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.1;
  background-color: #38445a;
  color: #ffffff;
  transition: background-color 0.3s;
}
.popup__content form#login-form .login__submit:hover {
  background-color: #283040;
}
@media (hover: none) {
  .popup__content form#login-form .login__submit {
    background-color: #38445a;
  }
}
.popup__content form#login-form .login__submit:disabled {
  background-color: #d3d4d6;
  cursor: default;
}
@media screen and (max-width: 1024px) {
  .popup__content form#login-form .login__submit {
    height: 54px;
    font-size: 17px;
    line-height: 1.1;
  }
}
@media screen and (max-width: 480px) {
  .popup__content form#login-form .login__submit {
    height: 48px;
    font-size: 16px;
    line-height: 1.1;
  }
}
.popup__content form#login-form .open__registrate {
  text-align: center;
}
.popup__content form#login-form .open__registrate p:first-child {
  display: inline;
  color: #2d2a26;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 14px;
}
.popup__content form#login-form .open__registrate p:last-child {
  display: inline;
  margin-left: 2px;
  color: #2d2a26;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.popup__content .popup__banner {
  margin-bottom: 7px;
}
.popup__content .popup__banner.second-step {
  display: none;
}
.popup__content h1 {
  margin-bottom: 24px;
  width: 100%;
  color: #2d2a26;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .popup__content h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .popup__content h1 {
    margin-bottom: 13px;
    font-size: 18px;
  }
}
.popup__content form#signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.popup__content form#signup-form .signup__input__wrapper {
  position: relative;
}
.popup__content form#signup-form .signup__input__wrapper input {
  padding-right: 50px;
}
.popup__content form#signup-form .signup__input__wrapper [data-view-password] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: max-content;
  height: 100%;
}
.popup__content form#signup-form .signup__input__wrapper [data-view-password].hide__password img:first-child {
  display: flex;
}
.popup__content form#signup-form .signup__input__wrapper [data-view-password].hide__password img:last-child {
  display: none;
}
.popup__content form#signup-form .signup__input__wrapper [data-view-password].show__password img:first-child {
  display: none;
}
.popup__content form#signup-form .signup__input__wrapper [data-view-password].show__password img:last-child {
  display: flex;
}
.popup__content form#signup-form .input__hints__wrapper {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
  margin-left: -7px;
  padding-top: 2px;
}
.popup__content form#signup-form .hint {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  color: #2d2a26;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
}
.popup__content form#signup-form .hint:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #9CA4BF;
}
.popup__content form#signup-form .hint.hidden {
  display: none;
}
.popup__content form#signup-form .hint.success:before {
  top: 2px;
  left: 7px;
  width: 12px;
  height: 12px;
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 2L4.33333 10L1 5.78947' stroke='%2300CA45' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50%;
}
.popup__content form#signup-form .hint.unsuccessful:before {
  top: 2px;
  left: 7px;
  width: 12px;
  height: 12px;
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none'                   xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2L2 10' stroke='%23C82C16' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 2L10 10' stroke='%23C82C16' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50%;
}
.popup__content form#signup-form .signup__row__phone {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
}
.popup__content form#signup-form .signup__row__phone .signup__row__phone__inner {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 16px;
  width: 100%;
  max-width: 68px;
  max-height: 48px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #9ca4bf;
  background: #f5f6fa;
  color: #2d2a26;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 125%;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
.popup__content form#signup-form .signup__row__phone .signup__code__img {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.popup__content form#signup-form .signup__row__phone .signup__code__value {
  position: relative;
  flex-grow: 1;
  color: #898996;
  font-weight: 500;
  line-height: 16px;
}
.popup__content form#signup-form .signup__birthdate {
  display: flex;
}
.popup__content form#signup-form .signup__birthdate .signup__birthdate__row {
  position: relative;
  width: 100%;
}
.popup__content form#signup-form .select__screen {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 45px 0 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  height: 48px;
  border: 1px solid #9CA4BF;
  border-radius: 4px;
  color: #999DAD;
  background-color: #F5F6FA;
  cursor: pointer;
}
.popup__content form#signup-form .select__screen.is-active:before {
  top: 45%;
  transform: rotate(180deg);
}
.popup__content form#signup-form .select__screen:before {
  position: absolute;
  top: 51%;
  right: 23px;
  transform: translateY(-50%);
  content: "";
  width: 12px;
  height: 8px;
  background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%239CA4BF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
  transition: all 0.3s;
}
.popup__content form#signup-form .select__list {
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 0;
  border: 1px solid #fff;
  border-radius: 4px;
  list-style-type: none;
  background-color: #f5f6fa;
  box-shadow: 0 4px 10px #bfbfc5;
  transition: all 0.3s;
  scrollbar-color: #efeff3 #d3d3e2;
  scrollbar-width: thin;
}
.popup__content form#signup-form .select__list.is-open {
  opacity: 1;
  visibility: visible;
  height: auto !important;
  max-height: 140px;
}
.popup__content form#signup-form .select__list::-webkit-scrollbar {
  width: 7px;
}
.popup__content form#signup-form .select__list::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #efeff3;
}
.popup__content form#signup-form .select__list::-webkit-scrollbar-thumb {
  background: #d3d3e2;
  border-radius: 3px;
}
.popup__content form#signup-form .select__list .select__item {
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #494949;
}
.popup__content form#signup-form .select__list .select__item.is-active {
  font-weight: 700;
  background-color: #9ca4bf;
}
.popup__content form#signup-form .select__list .select__item:hover {
  cursor: pointer;
  background-color: #9ca4bf;
}
.popup__content form#signup-form .signup__coupon__code__title {
  margin-bottom: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1A1A1A;
}
.popup__content form#signup-form .signup__coupon__code_wrapper {
  display: none;
  margin-bottom: 12px;
}
.popup__content form#signup-form .signup__coupon__code_wrapper.is-active {
  display: block;
}
.popup__content form#signup-form .signup__coupon__code__button {
  cursor: pointer;
  text-decoration: underline;
}
.popup__content form#signup-form .signup__submit {
  width: 100%;
  height: 54px;
  margin-bottom: 12px;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.1;
  background-color: #87f031;
  color: #2d2a26;
  transition: background-color 0.3s;
}
.popup__content form#signup-form .signup__submit:hover {
  background-color: #8fe846;
}
@media (hover: none) {
  .popup__content form#signup-form .signup__submit {
    background-color: #87f031;
  }
}
.popup__content form#signup-form .signup__submit:disabled {
  background-color: #c8f0a8;
  cursor: default;
}
@media screen and (max-width: 1024px) {
  .popup__content form#signup-form .signup__submit {
    height: 54px;
    font-size: 17px;
    line-height: 1.1;
  }
}
@media screen and (max-width: 480px) {
  .popup__content form#signup-form .signup__submit {
    height: 48px;
    margin-bottom: 9px;
    font-size: 16px;
    line-height: 1.1;
  }
}
.popup__content form#signup-form .open__login {
  text-align: center;
}
.popup__content form#signup-form .open__login p:first-child {
  display: inline;
  color: #2d2a26;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 14px;
}
.popup__content form#signup-form .open__login p:last-child {
  display: inline;
  margin-left: 2px;
  color: #2d2a26;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.popup__content form#signup-form .step__container {
  display: none;
}
.popup__content form#signup-form .step__container.is__active {
  display: initial;
}

#SignUp .progress-indicator-wrapper {
  display: flex;
  padding-bottom: 8px;
  align-items: center;
  justify-content: center;
}
#SignUp .progress-indicator-back-icon {
  display: none;
}
#SignUp .progress-indicator-back-icon.second-step {
  display: block;
  cursor: pointer;
}
#SignUp .progress-indicator, #SignUp .progress-indicator_inactive {
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2d2a26;
  border-radius: 50%;
  cursor: default;
}
#SignUp .progress-indicator:before, #SignUp .progress-indicator_inactive:before {
  display: block;
  width: 5px;
  height: 5px;
  content: "";
  background: #2d2a26;
  border-radius: 50%;
}
#SignUp .progress-indicator svg, #SignUp .progress-indicator_inactive svg {
  display: none;
}
#SignUp .progress-indicator.second-step, #SignUp .second-step.progress-indicator_inactive {
  border: 1px solid #12845b;
  background: #12845b;
  cursor: pointer;
}
#SignUp .progress-indicator.second-step:before, #SignUp .second-step.progress-indicator_inactive:before {
  display: none;
}
#SignUp .progress-indicator.second-step svg, #SignUp .second-step.progress-indicator_inactive svg {
  display: block;
  transform: scale(1.2);
}
#SignUp .progress-indicator-line {
  width: 64px;
  height: 1px;
  background: #9e9e9e;
}
#SignUp .progress-indicator-line.second-step {
  background: #555353;
}
#SignUp .progress-indicator_inactive {
  border: 1px solid #9e9e9e;
}
#SignUp .progress-indicator_inactive:before {
  background: #9e9e9e;
}
#SignUp .progress-indicator_inactive.second-step {
  background-color: transparent;
  border: 1px solid #555353;
  cursor: default;
}
#SignUp .progress-indicator_inactive.second-step:before {
  display: block;
  width: 5px;
  height: 5px;
  content: "";
  background: #555353;
  border-radius: 50%;
}

.popup__content:has(.forgotPass__popup__body) > h1 {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
}
.popup__content:has(.forgotPass__popup__body) > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  text-align: center;
}
.popup__content:has(.forgotPass__popup__body) img:first-of-type {
  margin: 0;
}

.forgotPass__popup__body {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  height: max-content;
}
.forgotPass__popup__body #confirmation__code {
  display: none;
}
.forgotPass__popup__body form:has(.input__row) button:only-of-type {
  margin-top: 24px;
}
.forgotPass__popup__body form input {
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  border: 1px solid #9CA4BF;
  border-radius: 4px;
  background-color: #F5F6FA;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
}
.forgotPass__popup__body form input::placeholder, .forgotPass__popup__body form input::-moz-placeholder, .forgotPass__popup__body form input::-ms-input-placeholder, .forgotPass__popup__body form input::-webkit-input-placeholder {
  color: #9CA4BF;
}
.forgotPass__popup__body form input::-webkit-outer-spin-button, .forgotPass__popup__body form input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.forgotPass__popup__body form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #fff inset;
  -moz-box-shadow: 0 0 0 100px #fff inset;
  box-shadow: 0 0 0 100px #fff inset;
}
.forgotPass__popup__body form input[type=number], .forgotPass__popup__body form input[type=number]:focus, .forgotPass__popup__body form input[type=number]:hover {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
.forgotPass__popup__body form input.error {
  border: 1px solid #C82C16;
}
.forgotPass__popup__body form fieldset.input__line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 8px;
  border: none;
}
.forgotPass__popup__body form fieldset.input__line div {
  position: relative;
}
.forgotPass__popup__body form fieldset.input__line div input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 45px;
  text-align: center;
}
.forgotPass__popup__body form fieldset.input__line div.active:before {
  content: "";
  position: absolute;
  display: block;
  left: 9px;
  bottom: 8px;
  width: 22px;
  height: 1px;
  background-color: #73788C;
}
.forgotPass__popup__body form fieldset.input__line.error div input {
  border: 1px solid #C82C16;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper {
  position: relative;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper input {
  padding-right: 50px;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper [data-view-password] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 16px;
  width: max-content;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
  transition: none;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper [data-view-password].hide__password img:first-child {
  display: flex;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper [data-view-password].hide__password img:last-child {
  display: none;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper [data-view-password].show__password img:first-child {
  display: none;
}
.forgotPass__popup__body form .input__row .forgotPass__input__wrapper [data-view-password].show__password img:last-child {
  display: flex;
}
.forgotPass__popup__body form .input__row .input__hints__wrapper {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
  margin-left: -7px;
  padding-top: 2px;
}
.forgotPass__popup__body form .input__row .hint {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  color: #2d2a26;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  white-space: nowrap;
}
.forgotPass__popup__body form .input__row .hint:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #9CA4BF;
}
.forgotPass__popup__body form .input__row .hint.hidden {
  display: none;
}
.forgotPass__popup__body form .input__row .hint.success:before {
  top: 3px;
  left: 7px;
  width: 6px;
  height: 6px;
  background: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 5L1 2.89474' stroke='%2324994C' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50%;
}
.forgotPass__popup__body form .input__row .hint.unsuccessful:before {
  top: 3px;
  left: 7px;
  width: 7px;
  height: 7px;
  background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_32675_200425)'%3E%3Cpath d='M5 1L1 5' stroke='%239CA4BF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L5 5' stroke='%239CA4BF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_32675_200425'%3E%3Crect width='6' height='6' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat 50%;
}
.forgotPass__popup__body form .input__row .phone__number__row {
  display: grid;
  grid-template-columns: 68px 1fr;
  justify-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
}
.forgotPass__popup__body form .input__row .phone__number__row .country__code__box {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 16px;
  width: 100%;
  max-width: 68px;
  max-height: 48px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #9ca4bf;
  background: #f5f6fa;
  color: #2d2a26;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 125%;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
.forgotPass__popup__body form .input__row .phone__number__row .country__code__box > img {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.forgotPass__popup__body form .input__row .phone__number__row .country__code__box > span {
  position: relative;
  flex-grow: 1;
  color: #898996;
  font-weight: 500;
  line-height: 16px;
}
.forgotPass__popup__body form .input__row .phone__number__hint {
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  line-height: 16px;
}
.forgotPass__popup__body .timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 26px;
  color: #2D2A26;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: pre;
}
.forgotPass__popup__body .timer button {
  background-color: transparent;
  text-decoration: underline;
  box-shadow: none;
  outline: none;
  border: none;
  text-align: center;
}
.forgotPass__popup__body button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #38445A;
  color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  box-shadow: 0 16px 32px -3px rgba(56, 68, 90, 0.15), 0 4px 8px -3px rgba(56, 68, 90, 0.15);
  transition: background-color 0.3s;
}
.forgotPass__popup__body button:hover {
  background-color: #181d26;
  box-shadow: 0 4px 4px 0 hsla(240, 8%, 61%, 0.6);
}
.forgotPass__popup__body button:disabled {
  background: #ebedef;
  box-shadow: none;
  cursor: default;
}
.forgotPass__popup__body button.simple {
  width: 100%;
  padding: 0;
  background-color: transparent;
  color: #2D2A26;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: underline;
  text-decoration-color: #2D2A26;
  box-shadow: none;
  outline: none;
  border: none;
  text-align: center;
  transition: text-decoration-color 0.3s ease-in-out;
}
.forgotPass__popup__body button.simple:hover {
  background-color: transparent;
  box-shadow: none;
  text-decoration-color: transparent;
}
.forgotPass__popup__body button:not(:only-child) {
  margin-bottom: 8px;
}
.forgotPass__popup__body button:not(:only-child):last-child {
  margin-bottom: 0;
}
.forgotPass__popup__body p.sms__hint {
  color: #2D2A26;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}
.forgotPass__popup__body p button {
  width: 100%;
  padding: 0;
  background-color: transparent;
  color: #2D2A26;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: underline;
  text-decoration-color: #2D2A26;
  box-shadow: none;
  outline: none;
  border: none;
  text-align: center;
  transition: text-decoration-color 0.3s ease-in-out;
}
.forgotPass__popup__body p button:after {
  content: "?";
  display: inline;
  width: max-content;
  height: auto;
  font-weight: 400;
  text-decoration: none;
}
.forgotPass__popup__body p button:hover {
  background-color: transparent;
  box-shadow: none;
  text-decoration-color: transparent;
}

.popup__quit__container {
  position: relative;
  max-width: 530px !important;
  padding: 110px 15px 40px;
}

.popup__top__img {
  position: absolute;
  top: -66px;
  left: 50%;
  transform: translateX(-50%);
  width: 166px;
  height: 170px;
}
@media screen and (max-width: 480px) {
  .popup__top__img {
    position: static;
    transform: translateX(0);
    margin: 0 auto;
  }
}

.popup__support {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 20px;
  color: #858585;
}
.popup__support img {
  margin-right: 5px;
}

.popup__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #2d2a26;
}
.popup__title__accent {
  margin-bottom: 10px;
  font-weight: 700 !important;
  font-size: 18px !important;
}
.popup__title__default {
  margin-bottom: 20px;
}
.popup__title span {
  font-weight: 500;
  line-height: 20px;
}

.popup__btns {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-gap: 18px;
}

.popup__btn__fill {
  width: 100%;
  max-width: 300px;
  height: 56px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background-color: #39445a;
  transition: all 0.3s;
}
.popup__btn__fill:hover {
  background-color: #283040;
}

.popup__btn__underlined {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #2d2a26;
  color: #2d2a26;
  transition: all 0.3s;
}
.popup__btn__underlined:hover {
  text-decoration-color: transparent;
}
@media (hover: none) {
  .popup__btn__underlined {
    text-decoration-color: #2d2a26;
  }
}