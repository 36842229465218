.popup__content {
    .popup__banner {
        margin-bottom: 7px;

        &.second-step {
            display: none;
        }
    }

    h1 {
        margin-bottom: 24px;
        width: 100%;
        color: $text-dark-color;
        font-size: 18px;
        font-weight: $font-bold;
        line-height: 21.6px;
        text-transform: uppercase;
        text-align: center;

        @media screen and (max-width: $laptop-xl) {
            font-size: 20px;
        }
    
        @media screen and (max-width: $mobile-xl) {
            margin-bottom: 13px;
            font-size: 18px;
        }
    }

    form#signup-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .signup__input__wrapper {
            position: relative;

            input {
                padding-right: 50px;
            }

            [data-view-password] {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                align-items: center;
                justify-content: center;
                padding: 16px;
                width: max-content;
                height: 100%;

                &.hide__password {
                    img:first-child {
                        display: flex;
                    }
    
                    img:last-child {
                        display: none;
                    }
                }
    
                &.show__password {
                    img:first-child {
                        display: none;
                    }
    
                    img:last-child {
                        display: flex;
                    }
                }
            }
        }

        .input__hints__wrapper {
            width: 85%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 3px;
            margin-left: -7px;
            padding-top: 2px;
        }

        .hint {
            display: inline-block;
            position: relative;
            padding-left: 22px;
            color: #2d2a26;
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
            white-space: nowrap;

            &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 7px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #9CA4BF;
            }

            &.hidden {
                display: none;
            }
            &.success {
                &:before {
                    top: 2px;
                    left: 7px;
                    width: 12px;
                    height: 12px;
                    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 2L4.33333 10L1 5.78947' stroke='%2300CA45' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50%
                }
            }
            &.unsuccessful {
                &:before {
                    top: 2px;
                    left: 7px;
                    width: 12px;
                    height: 12px;
                    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none'                   xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2L2 10' stroke='%23C82C16' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 2L10 10' stroke='%23C82C16' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50%;
                }
            }
        }

        .signup__row__phone {
            display: flex;
            align-items: center;
            justify-items: center;
            justify-content: center;
            gap: 12px;
            width: 100%;

            .signup__row__phone__inner {
                display: flex;
                justify-content: center;
                align-content: center;
                padding: 16px;
                width: 100%;
                max-width: 68px;
                max-height: 48px;
                height: 100%;
                border-radius: 4px;
                border: 1px solid #9ca4bf;
                background: #f5f6fa;
                color: #2d2a26;
                font-size: 13px;
                font-weight: 500;
                font-style: normal;
                line-height: 125%;
                outline: none;
                -webkit-appearance: none;
                appearance: none;
                box-sizing: border-box;
            }

            .signup__code__img {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }

            .signup__code__value {
                position: relative;
                flex-grow: 1;
                color: #898996;
                font-weight: 500;
                line-height: 16px;
            }
        }

        .signup__birthdate {
            display: flex;

            .signup__birthdate__row {
                position: relative;
                width: 100%;
            }
        }

        .select__screen {
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 45px 0 16px;
            font-size: 13px;
            font-weight: 500;
            line-height: 24px;
            height: 48px;
            border: 1px solid #9CA4BF;
            border-radius: 4px;
            color: #999DAD;
            background-color: #F5F6FA;
            cursor: pointer;

            &.is-active {
                &:before {
                    top: 45%;
                    transform: rotate(180deg);
                }
            }

            &:before {
                position: absolute;
                top: 51%;
                right: 23px;
                transform: translateY(-50%);
                content: '';
                width: 12px;
                height: 8px;
                background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%239CA4BF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
                transition: all .3s;
            }
        }

        .select__list {
            overflow: auto;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 0;
            border: 1px solid #fff;
            border-radius: 4px;
            list-style-type: none;
            background-color: #f5f6fa;
            box-shadow: 0 4px 10px #bfbfc5;
            transition: all .3s;
            scrollbar-color: #efeff3 #d3d3e2;
            scrollbar-width: thin;

            &.is-open {
                opacity: 1;
                visibility: visible;
                height: auto !important;
                max-height: 140px;
            }

            &::-webkit-scrollbar {
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 3px;
                background-color: #efeff3;
            }

            &::-webkit-scrollbar-thumb {
                background: #d3d3e2;
                border-radius: 3px;
            }

            .select__item {
                font-size: 13px;
                font-weight: 500;
                line-height: 32px;
                text-align: center;
                color: #494949;

                &.is-active {
                    font-weight: 700;
                    background-color: #9ca4bf;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #9ca4bf;
                }
            }
        }

        .signup__coupon__code__title {
            margin-bottom: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #1A1A1A;
        }

        .signup__coupon__code_wrapper {
            display: none;
            margin-bottom: 12px;

            &.is-active {
                display: block;
            }
        }

        .signup__coupon__code__button {
            cursor: pointer;
            text-decoration: underline;

        }

        .signup__submit {
            width: 100%;
            height: 54px;
            margin-bottom: 12px;
            border: none;
            border-radius: 4px;
            font-size: 17px;
            font-weight: $font-semibold;
            line-height: 1.1;
            background-color: $button-green-primary-color;
            color: $text-dark-color;
            transition: background-color .3s;
        
            &:hover {
                background-color: $button-green-primary-hover-color;
            }
        
            @media (hover: none) {
                background-color: $button-green-primary-color;
            }
        
            &:disabled {
                background-color: $button-green-primary-disabled-color;
                cursor: default;
            }
        
            @media screen and (max-width: $laptop-xl) {
                height: 54px;
                font-size: 17px;
                line-height: 1.1;
            }
        
            @media screen and (max-width: $mobile-xl) {
                height: 48px;
                margin-bottom: 9px;
                font-size: 16px;
                line-height: 1.1;
            }
        }

        .open__login {
            text-align: center;

            p:first-child {
                display: inline;
                color: $text-dark-color;
                font-size: 12px;
                font-weight: $font-semibold;
                font-style: normal;
                line-height: 14px; 
            }

            p:last-child {
                display: inline;
                margin-left: 2px;
                color: $text-dark-color;
                font-size: 12px;
                font-weight: $font-bold;
                font-style: normal;
                line-height: 14px; 
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .step__container {
            display: none;

            &.is__active {
                display: initial;
            }
        }
    }
}

#SignUp {
    .progress-indicator-wrapper {
        display: flex;
        padding-bottom: 8px;
        align-items: center;
        justify-content: center;
    }
    
    .progress-indicator-back-icon {
        display: none;

        &.second-step {
            display: block;
            cursor: pointer;
        }
    }

    .progress-indicator {
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $text-dark-color;
        border-radius: 50%;
        cursor: default;
    
        &:before {
            display: block;
            width: 5px;
            height: 5px;
            content: "";
            background: $text-dark-color;
            border-radius: 50%;
        }
    
        svg {
            display: none;
        }
    
        &.second-step {
            border: 1px solid $signup-indicator-step;
            background: $signup-indicator-step;
            cursor: pointer;

            &:before {
                display: none;
            }
    
            svg {
                display: block;
                transform: scale(1.2);
            }
        }
    }
    
    .progress-indicator-line {
        width: 64px;
        height: 1px;
        background: $signup-indicator-line;

        &.second-step {
            background: $signup-indicator-background;
        }
    }
    
    .progress-indicator_inactive {
        @extend .progress-indicator;
        border: 1px solid $signup-indicator-line;
    
        &:before {
            background: $signup-indicator-line;
        }
    
        &.second-step {
            background-color: transparent;
            border: 1px solid $signup-indicator-background;
            cursor: default;
    
            &:before {
                display: block;
                width: 5px;
                height: 5px;
                content: "";
                background: $signup-indicator-background;
                border-radius: 50%;
            }
        }
    }
}
