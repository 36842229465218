.popup__content {
    h1 {
        margin-bottom: 24px;
        width: 100%;
        color: $text-dark-color;
        font-size: 18px;
        font-weight: $font-bold;
        line-height: 21.6px;
        text-transform: uppercase;
        text-align: center;

        @media screen and (max-width: $laptop-xl) {
            font-size: 20px;
        }
    
        @media screen and (max-width: $mobile-xl) {
            margin-bottom: 13px;
            font-size: 18px;
        }
    }

    form#login-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .login__input__wrapper {
            position: relative;

            input {
                padding-right: 50px;
            }

            [data-view-password] {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                align-items: center;
                justify-content: center;
                padding: 16px;
                width: max-content;
                height: 100%;

                &.hide__password {
                    img:first-child {
                        display: flex;
                    }
    
                    img:last-child {
                        display: none;
                    }
                }
    
                &.show__password {
                    img:first-child {
                        display: none;
                    }
    
                    img:last-child {
                        display: flex;
                    }
                }
            }
        }

        .open__forgotPass {
            display: block;
            color: $popup-link-color;
            font-size: 12px;
            font-weight: $font-medium;
            line-height: 14px;
            text-align: center;
            text-decoration: underline;
            text-decoration-color: $popup-link-color;
            text-decoration-thickness: .2px;
            transition: text-decoration-color .3s ease-in-out;
            cursor: pointer;
        
            &:hover {
                text-decoration-color: transparent;
            }
        }

        .login__submit {
            width: 100%;
            height: 54px;
            margin-bottom: 10px;
            border: none;
            border-radius: 4px;
            font-size: 17px;
            font-weight: $font-semibold;
            line-height: 1.1;
            background-color: $button-submit-color;
            color: $text-light-color;
            transition: background-color .3s;
        
            &:hover {
                background-color: $button-submit-hover-color;
            }
        
            @media (hover: none) {
                background-color: $button-submit-color;
            }
        
            &:disabled {
                background-color: $button-submit-disabled-color;
                cursor: default;
            }
        
            @media screen and (max-width: $laptop-xl) {
                height: 54px;
                font-size: 17px;
                line-height: 1.1;
            }
        
            @media screen and (max-width: $mobile-xl) {
                height: 48px;
                font-size: 16px;
                line-height: 1.1;
            }
        }

        .open__registrate {
            text-align: center;

            p:first-child {
                display: inline;
                color: $text-dark-color;
                font-size: 12px;
                font-weight: $font-semibold;
                font-style: normal;
                line-height: 14px; 
            }

            p:last-child {
                display: inline;
                margin-left: 2px;
                color: $text-dark-color;
                font-size: 12px;
                font-weight: $font-bold;
                font-style: normal;
                line-height: 14px; 
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
