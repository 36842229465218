body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $background-dark-color;
    overflow-x: hidden;

    .center__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1920px;
    }

    main, footer {
        transition: all .3s;

        @media screen and (min-width: 1025px) {
            padding-left: $side-menu-close-width;
        }
    }

    @media screen and (max-width: $mobile-xl) {
        footer {
            padding-left: 10px;
        }
    }

    &:has(:is(.sidemenu.is__open)) {
        main, footer {
            @media screen and (min-width: 1200px)  {
                padding-left: $side-menu-open-width;
                overflow-x: hidden;
            }
        }
    }
}

body, * {
    scrollbar-width: thin;
    scrollbar-color:  $scrollbar-thumb-color $scrollbar-track-color;
}
