@keyframes mobile-app-banner-slide-top {
  0% {
    transform: translate(-50%, 200px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.mobileAppBanner {
  display: none;
  position: fixed;
  width: calc(100% - 40px);
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  padding: 12px 46px 12px 12px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  line-height: 17px;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  overflow: hidden;
  animation: mobile-app-banner-slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  z-index: 999;
}

@media screen and (max-width: $tablet-m) {
  .mobileAppBanner {
    display: flex;
    bottom: 75px;
    max-height: 60px;
  }
}

@media screen and (max-width: $mobile-xl) {
  .mobileAppBanner {
    display: flex;
    bottom: 65px;
    max-height: 60px;
  }
}

.mobileAppBanner_logo {
  display: flex;
}

.mobileAppBanner_text {
  display: flex;
  flex-direction: column;
  padding: 0 3px 0 8px;
  flex: auto;
}

.mobileAppBanner_title {
  font-family: "Montserrat", "Arial", sans-serif !important;
  font-weight: bold;
  font-size: 14px;
}

.mobileAppBanner_description {
  font-family: "Montserrat", "Arial", sans-serif !important;
  color: #929292;
  font-size: 10px;
}

.mobileAppBanner_button {
  font-family: "Arial", sans-serif !important;
  background: #87f031;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  text-transform: none;
  min-width: 0;
  min-height: 0;
  padding: 9px 12px;
}

.mobileAppBanner_close {
  position: absolute;
  right: 8px;
  top: 10px;
}

.mobileAppBanner_close span {
  display: flex;
}

.iconButton {
  margin-left: auto;
  z-index: 1;
  stroke: #999DAD;
}

.iconButton svg {
  stroke: #999DAD;
}

.iconButton:hover,
.iconButton:active {
  background: transparent !important;
}

.iconButton:hover svg,
.iconButton:active svg {
  stroke: #73788C;
}

@media (hover: none) {
  .iconButton {
    background: transparent !important;
    animation: none !important;
  }
}
